.crystalpower {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100vw;
}

.crystalpower .cpherokep {
  align-items: center;
  align-self: stretch;
  background-image: url(../../img2/herokep.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 897px;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  
}

.crystalpower .cpframe {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
  background-position: 50% 50%;
  background-size: cover;
  transition: opacity 2s ease-in-out;
}

.crystalpower .cpimg {
  position: absolute;
  top: 801px; /* Ezt a pozíciót szükség szerint módosíthatod */
  height: 35px;
  width: auto; /* Automatikusan méretezi a képet a magassághoz */
}

.crystalpower .cpimg.left-arrow {
  left: 50px; /* Bal oldali gomb pozíciója */
  background-color: transparent;
  top: 90vh;
}

.crystalpower .cpimg.right-arrow {
  right: 50px; /* Jobb oldali gomb pozíciója */
  background-color: transparent;
  top: 90vh;
}

.crystalpower .cpoverlap-group {
  height: 782px;
  left: 1px;
  position: absolute;
  top: 19px;
  width: 100%;
}

.crystalpower .cpnavcont {
  height: 207px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.crystalpower .cplogo {
  background-position: 50% 50%;
  background-size: cover;
  height: 90px;
  z-index: 1000;
  left: 20px;
  position: absolute;
  top: 20px;
  width: 51px;
}

.crystalpower .cpnavlinkek {
  height: 35px;
  left:20%;
  position: absolute;
  top: 65px;
  width: 651px;
}

.crystalpower .cpkezdolapwrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 18px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.crystalpower .cpnavvonal {
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 71px;
}

.crystalpower .cptext-wrapper {
  color: white;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.crystalpower .cpgaleriawrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 128px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.crystalpower .cpdiv {
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 57px;
}

.crystalpower .cpkapcsolatwrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 222px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.crystalpower .cpnavvonal-2 {
  align-self: stretch;
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 100%;
}

.crystalpower .cparainkwrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 337px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.crystalpower .cpfoglalaswrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 425px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.crystalpower .cpnavvonal-3 {
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 64px;
}
.crystalpower .cpnavvonal-4 {
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 64px;
}

.crystalpower .cpinformaciowrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 526px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.crystalpower .cpnyelvek {
  align-items: center;
  border-radius: 5px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  right: 50px;
  overflow: hidden;
  position: absolute;
  top: 57px;
}

.crystalpower .cpdiv-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.crystalpower .cpimg-2 {
  height: 40px;
  position: relative;
  width: 40px;
}

.crystalpower .cptext-wrapper-2 {
  color: #ffffff;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.crystalpower .cpangol {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 34.04px;
  position: relative;
  width: 100%;
}

.crystalpower .cpangolflag {
  height: 34.04px;
  position: relative;
  width: 40px;
}

.crystalpower .cpframe-2 {
  height: 80vh;
  left: 144px;
  position: absolute;
  top: 14vh;
  width: 552px;
  overflow: hidden;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.crystalpower .cptext-wrapper-3 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, rgb(254, 254, 254) 0%, rgb(246, 38, 129) 100%);
  background-clip: text;
  color: white;
  font-size: 75px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-fill-color: transparent;
  top: 291px;
  white-space: nowrap;
  width: 552px;
  overflow: hidden;
  position: relative;
}

.crystalpower .cptext-wrapper-3::before {
  content: 'Crystal Power';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(246, 38, 129, 0) 0%,
    rgba(246, 38, 129, 0.5) 50%,
    rgba(246, 38, 129, 0) 100%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s forwards;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.crystalpower .cptext-wrapper-4 {
  color: white;
  
  font-size: 30px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 381px;
}

.crystalpower .cpdiv-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 5% 10% 5%;
  position: relative;
  width: 80%;
}

.crystalpower .cpdiv-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.crystalpower .cpframe-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.crystalpower .cpcrystalpowerwrap {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.crystalpower .cptext-wrapper-5 {
  color: #000000;
  
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.crystalpower .cpcrystal-vonal {
  align-self: stretch;
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(246, 38, 129) 100%);
  border-radius: 10px;
  height: 3px;
  position: relative;
  width: 100%;
}

.crystalpower .cpferohely {
  align-items: center;
  background-color: #5d7329;
  border-radius: 15px;
  display: flex;
  gap: 8px;
  overflow: hidden;
  padding: 1px 14px;
  position: relative;
}

.crystalpower .cptext-wrapper-6 {
  color: #ffffff;
  
  font-size: 23px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.crystalpower .cpicon-user {
  height: 22px;
  position: relative;
  width: 20px;
}

.crystalpower .cpszoveg {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 10px 0px;
  position: relative;
  width: 100%;
}

.crystalpower .cpp {
  align-self: stretch;
  color: #000000;
  flex: 1;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.crystalpower .cpikonok {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  padding: 10px 0px;
  position: relative;
  width: 100%;
}

.crystalpower .cpdiv-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  padding: 0px 1px;
  position: relative;
}

.crystalpower .cpimg-3 {
  height: 25px;
  position: relative;
  width: 25px;
}

.crystalpower .cptext-wrapper-7 {
  color: #000000;
  
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.crystalpower .cpdiv-5 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  position: relative;
}

.crystalpower .cpdiv-6 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  height: 25px;
  padding: 10px 0px;
  position: relative;
}

.crystalpower .cpimg-4 {
  height: 25px;
  margin-bottom: -10px;
  margin-top: -10px;
  position: relative;
  width: 25px;
}

.crystalpower .cptext-wrapper-8 {
  color: #000000;
  
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -5px;
  margin-top: -7px;
  position: relative;
  width: fit-content;
}

.crystalpower .cptext-wrapper-9 {
  color: #000000;
  flex: 1;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.crystalpower .cpgombwrap {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.crystalpower .cpgombcont {
  height: 115px;
  position: relative;
  width: 236px;
}

.crystalpower .cpgombvonal {
  background-color: #bf9f50;
  border-radius: 20px;
  height: 3px;
  left: 62px;
  position: absolute;
  top: 10px;
  width: 113px;
}

.crystalpower .cpgomb {
  top: 27px;
  align-items: center;
  background-color: #5d7329;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  left: 3px;
  overflow: hidden;
  padding: 15px 29px;
  position: relative;
}

.crystalpower .cptext-wrapper-10 {
  color: #ffffff;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}
.left-arrow {
  height: 35px;
}

.right-arrow {
  height: 35px;
}
.Change-Image {
  width: 100vw;
  height: 100vh;
  margin: 0;
  position: absolute;
  object-fit: cover; /* This ensures the image covers the container while maintaining its aspect ratio */
  object-position: 50% 50%;
}
.Change-Image-Info {
  width: 100vw;
  height: 100vh;
  margin: 0;
  position: absolute;
  object-fit: cover; /* This ensures the image covers the container while maintaining its aspect ratio */
  object-position: 50% 50%;
}