.iphonecrystal {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.iphonecrystal .mangocontainer-wrapper {
  background-color: #ffffff;
  height: auto;
  width: 100vw;
}

.iphonecrystal .mangocontainer {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.iphonecrystal .overlap-group-wrapper {
  height: 91vh;
  position: relative;
  width: 100vw;
}

.iphonecrystal .overlap-group-4 {
  height: 91vh;
  position: relative;
}

.iphonecrystal .hero-3 {
  background-image: url(../../img2/hero-7.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 91vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
}

.iphonecrystal .frame-52 {
  background-position: 50% 50%;
  background-size: cover;
  height: 76px;
  z-index: 1000;
  left: 20px;
  position: relative;
  width: 42px;
  top:20px;
}

.iphonecrystal .frame-53 {
  height: 736px;
  left: 0;
  position: absolute;
  top: 5vh;
  width: 100vw;
}

.iphonecrystal .kuruk-hill-head-3 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(246, 38, 129) 100%);
  background-clip: text;
  color: transparent;
  
  font-size: 50px;
  font-weight: 400;
  left: 26px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-fill-color: transparent;
  top: 235px;
  white-space: nowrap;
  width: 381px;
}

.iphonecrystal .text-wrapper-26 {
  color: white;
  
  font-size: 30px;
  font-weight: 400;
  left: 26px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 305px;
}

.iphonecrystal .frame-54 {
  height: 50px;
  left: 18px;
  position: absolute;
  top: 615px;
  width: 394px;
}

.iphonecrystal .menu-alt-svgrepo-3 {
  height: 60px;
  left: 351px;
  position: absolute;
  top: 24px;
  width: 60px;
}

.iphonecrystal .nyelvek-4 {
  align-items: center;
  border-radius: 5px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  left: 233px;
  overflow: hidden;
  position: absolute;
  top: 38px;
}

.iphonecrystal .div-7 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.iphonecrystal .img-11 {
  height: 30px;
  position: relative;
  width: 30px;
}

.iphonecrystal .text-wrapper-27 {
  color: #ffffff;
  
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.iphonecrystal .angol-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 34.04px;
  position: relative;
  width: 100%;
}

.iphonecrystal .frame-55 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: auto;
  position: relative;
  width: 100vw;
}

.iphonecrystal .frame-56 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 80%;
    margin-left: 7.5%;
}

.iphonecrystal .frame-57 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.iphonecrystal .text-wrapper-28 {
  color: #000000;
  
  font-size: 33px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.iphonecrystal .frame-58 {
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(246, 38, 129) 100%);
  border-radius: 20px;
  height: 3px;
  position: relative;
  width: 215px;
}

.iphonecrystal .ferohely-4 {
  align-items: center;
  background-color: #5d7329;
  border-radius: 15px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  height: 24px;
  overflow: hidden;
  padding: 1px 14px;
  position: relative;
  transform: translateX(30px);
}

.iphonecrystal .text-wrapper-29 {
  color: #ffffff;
  
  font-size: 19px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -2px;
  position: relative;
  width: fit-content;
}

.iphonecrystal .icon-user-4 {
  height: 20px;
  margin-right: -0.5px;
  position: relative;
  width: 20px;
}

.iphonecrystal .frame-59 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 30px 0px 10px;
  position: relative;
  width: 359px;
}

.iphonecrystal .h-l-szob-ban-egy-4 {
  color: #000000;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 359px;
}

.iphonecrystal .frame-60 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 359px;
}

.iphonecrystal .frame-61 {
  align-items: center;
  display: flex;
  height: 37px;
  justify-content: space-between;
  padding: 8px 0px;
  position: relative;
  width: 270px;
}

.iphonecrystal .frame-62 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  margin-bottom: -2px;
  margin-top: -2px;
  padding: 0px 1px;
  position: relative;
}

.iphonecrystal .img-12 {
  height: 25px;
  position: relative;
  width: 25px;
}

.iphonecrystal .text-wrapper-30 {
  color: #000000;
  
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.iphonecrystal .frame-63 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  margin-bottom: -2px;
  margin-top: -2px;
  position: relative;
}

.iphonecrystal .frame-64 {
  align-items: center;
  display: flex;
  height: 37px;
  justify-content: space-between;
  padding: 8px 0px;
  position: relative;
  width: 310px;
}

.iphonecrystal .frame-65 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  height: 25px;
  margin-bottom: -2px;
  margin-top: -2px;
  padding: 10px 0px;
  position: relative;
}

.iphonecrystal .img-13 {
  height: 25px;
  margin-bottom: -10px;
  margin-top: -10px;
  position: relative;
  width: 25px;
}

.iphonecrystal .text-wrapper-31 {
  color: #000000;
  
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -6px;
  margin-top: -8px;
  position: relative;
  width: fit-content;
}

.iphonecrystal .frame-66 {
  align-items: center;
  display: flex;
  height: 37px;
  justify-content: space-between;
  padding: 8px 0px;
  position: relative;
  width: 315px;
}

.iphonecrystal .frame-67 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 90px;
  height: 37px;
  padding: 8px 0px;
  position: relative;
}

.iphonecrystal .text-wrapper-32 {
  color: #000000;
  flex: 1;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.iphonecrystal .frame-68 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 7px;
  height: auto;
  justify-content: center;
  padding: 10px;
  position: relative;
  width: 359px;
}

.iphonecrystal .frame-69 {
  background-color: #bf9f50;
  height: 3px;
  margin-top: -6.5px;
  position: relative;
  width: 106px;
}

.iphonecrystal .frame-70 {
  align-items: center;
  background-color: #5d7329;
  border-radius: 5px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  margin-bottom: -6.5px;
  overflow: hidden;
  padding: 10px;
  position: relative;
}

.iphonecrystal .text-wrapper-33 {
  color: #ffffff;
  
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}
