.stats-dashboard {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f4;
  }
  
  .stats-section {
    background: white;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .stats-section h2 {
    margin-bottom: 10px;
  }
  
  .date-inputs {
    display: flex;
    gap: 10px;
  }
  
  .date-input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
    font-size: 13px;
  }
  
  .date-input.day {
    width: 80px;
  }
  
  .date-input.month,
  .date-input.year {
    width: 40px;
  }
  
  .send-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .send-button:hover {
    background-color: #0056b3;
  }
  
  .info, .view-info, .time-info, .booking-info, .breakfast-info, .house-view-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .view-info, .time-info, .booking-info, .breakfast-info, .house-view-info {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  
  .view-data, .time-data, .booking-data, .breakfast-data, .house-view-data {
    flex: 1;
  }
  
  .donut-chart {
    flex: 1;
    max-width: 300px;
  }