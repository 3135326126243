.gpes-resolution {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
.custom-link {
  color: inherit; /* Inherit the color from the parent element */
  text-decoration: none; /* Remove underline */
}

.custom-link:hover {
  color: inherit; /* Optional: maintain color on hover */
  text-decoration: underline; /* Optional: underline on hover */
}
.gpes-resolution .div {
  background-color: #ffffff;
  height: auto;
  overflow-x: hidden;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.gpes-resolution .hero {
  align-items: center;
  align-self: stretch;
  background-image: url(../../img2/maintop.jpg);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100vh;
  min-height: 100vh;
  position: relative;
  width: 100%;
}

.gpes-resolution .overlap-group {
  height: auto;
  position: relative;
  width: 100%;
}

.gpes-resolution .herocontentwrap {
  height: 50vh;
  left: 144px;
  position: absolute;
  top: 15vh;
  width: 381px;
  opacity: 0;
  transform: translateY(50px) scale(0.8);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.gpes-resolution .herocontentwrap.animate {
  opacity: 1;
  transform: translateY(0) scale(1);
}

.gpes-resolution .kuruk-hill-head {
  color: white;
  font-size: 75px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 235px;
  white-space: nowrap;
  width: 381px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8), /* 3D effect */
               -2px -2px 4px rgba(0, 0, 0, 0.8); /* 3D effect */
  outline-offset: -2px; /* Adjust outline offset */
}

.gpes-resolution .text-wrapper {
  color: white;
  font-size: 30px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 324px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8), /* 3D effect */
               -2px -2px 4px rgba(0, 0, 0, 0.8); /* 3D effect */
  outline-offset: -2px; /* Adjust outline offset */
}

.gpes-resolution .mer-ljetek-el-a-k {
  color: #ffffff;
  font-size: 17px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 358px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8), /* 3D effect */
               -2px -2px 4px rgba(0, 0, 0, 0.8); /* 3D effect */
  outline-offset: -2px; /* Adjust outline offset */
}

.gpes-resolution .folaljmostgomb {
  align-items: center;
  background-color: rgba(93, 115, 41, 1);
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  left: 0;
  overflow: hidden;
  padding: 10px 30px;
  position: absolute;
  top: 435px;
}

.gpes-resolution .text-wrapper-2 {
  color: white;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.gpes-resolution .navcont {
  height: 207px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.gpes-resolution .logo {
  background-position: 50% 50%;
  background-size: cover;
  height: 90px;
  z-index: 1000;
  left: 20px;
  position: absolute;
  top: 20px;
  width: 51px;
}

.gpes-resolution .navlinkek {
  height: 35px;
  left:25%;
  position: absolute;
  top: 65px;
  width: 651px;
}

.gpes-resolution .kezdolapwrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 18px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.gpes-resolution .navvonal {
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 71px;
}

.gpes-resolution .text-wrapper-3 {
  color: white;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.gpes-resolution .galeriawrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 128px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.gpes-resolution .navvonal-2 {
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 57px;
}

.gpes-resolution .kapcsolatwrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 222px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.gpes-resolution .navvonal-3 {
  align-self: stretch;
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 100%;
}

.gpes-resolution .arainkwrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 337px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.gpes-resolution .foglalaswrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 425px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.gpes-resolution .navvonal-4 {
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 64px;
}

.gpes-resolution .informaciowrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 526px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.gpes-resolution .nyelvek {
  align-items: center;
  border-radius: 5px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  right: 50px;
  overflow: hidden;
  position: absolute;
  top: 57px;
}

.gpes-resolution .div-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.gpes-resolution .img {
  height: 40px;
  position: relative;
  width: 40px;
}

.gpes-resolution .text-wrapper-4 {
  color: #ffffff;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.gpes-resolution .angol {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 34.04px;
  position: relative;
  width: 100%;
}

.gpes-resolution .angolflag {
  height: 34.04px;
  position: relative;
  width: 40px;
}

.gpes-resolution .hzaink {
  height: auto;
  left: 15%;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 70%;
  top: 30px;
}

.gpes-resolution .headingwrap {
  height: 67px;
  left: 70px;
  position: relative;
  top: 0;
  width: 188px;
}

.gpes-resolution .overlap-group-2 {
  height: 66px;
  position: relative;
}

.gpes-resolution .vonal {
  background-color: #bf9f50;
  height: 3px;
  left: 0;
  position: absolute;
  top: 0;
  width: 91px;
}

.gpes-resolution .h-zaink-head {
  color: #5d7329;
  
  font-size: 50px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 2px;
}

.gpes-resolution .frame {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 45px;
  left: 6%;
  position: relative;
  top: 40px;
  width: 88%;
}

.gpes-resolution .szoveg {
  align-self: stretch;
  color: #000000;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.gpes-resolution .p {
  align-self: stretch;
  color: #000000;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.gpes-resolution .szolodisz {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.gpes-resolution .szolovonal {
  background-color: #bf9f50;
  height: 2px;
  position: relative;
  width: 117px;
}

.gpes-resolution .szolo {
  background-image: url('../../img2/szolo.png');
  background-position: 50% 50%;
  background-size: cover;
  height: 60px;
  position: relative;
  width: 60px;
}

.gpes-resolution .frame-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  top: 70px;
  width: 120%;
  margin-left: -10%;
}

.gpes-resolution .div-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10%;
  height: 440px;
  position: relative;
  width: 100%;
}

.gpes-resolution .cystal {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding: 9px 0px;
  position: relative;
}

.gpes-resolution .crystalkep {
  align-self: stretch;
  background-image: url('../../img2/crystalkep.png');
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 15px;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
  height: 80%;
}

.gpes-resolution .div-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  padding: 10px 0px;
  position: relative;
  width: 100%;
}

.gpes-resolution .crystal-power-head {
  color: #5d7329;
  
  font-size: 47px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 70%;
}

.gpes-resolution .ferohely {
  align-items: center;
  background-color: #5d7329;
  border-radius: 15px;
  display: flex;
  gap: 8px;
  overflow: hidden;
  padding: 1px 14px;
  position: relative;
}

.gpes-resolution .text-wrapper-5 {
  color: #ffffff;
  
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.gpes-resolution .icon-user {
  height: 24px;
  position: relative;
  width: 24px;
}

.gpes-resolution .frame-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gpes-resolution .szimbolumok {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 13px;
  justify-content: center;
  padding: 13px 0px;
  position: relative;
}

.gpes-resolution .img-2 {
  height: 25px;
  position: relative;
  width: 25px;
}

.gpes-resolution .elvalasztovonal {
  background-color: #bf9f50;
  border-radius: 20px;
  height: 22px;
  position: relative;
  width: 2px;
}

.gpes-resolution .frame-4 {
  flex: 0 0 auto;
  height: 14.73px;
  margin-right: -1px;
  position: relative;
}

.gpes-resolution .crystalkep-2 {
  align-self: stretch;
  background-image: url('../../img2/crystalkep2.png');
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 15px;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
  height: 80%;
}

.gpes-resolution .frame-5 {
  flex: 0 0 auto;
  height: 14.73px;
  position: relative;
}

.gpes-resolution .frame-wrapper {
  align-self: stretch;
  height: auto;
  position: relative;
  width: 100%;
  transform: translateY(100px);
}

.gpes-resolution .cystal-wrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 10px;
  height: 487px;
  justify-content: center;
  left: 26%;
  position: relative;
  width: 48%;
}

.gpes-resolution .crystalkep-3 {
  align-self: stretch;
  background-image: url('../../img2/crystalkep3.png');
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 15px;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
  height: 337px;
}

.gpes-resolution .crystal-power-head-2 {
  color: #5d7329;
  
  font-size: 47px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.gpes-resolution .geleriawrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 3850px;
  justify-content: center;
  left: 13%;
  position: relative;
  top: 300px;
  width: 70%;
}

.gpes-resolution .galeriacontainer {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 13px;
  justify-content: center;
  padding: 0px 100px;
  position: relative;
  width: 90%;
}

.gpes-resolution .galeriaheadingwrap {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 7px;
  height: 135px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.gpes-resolution .vonal-2 {
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 91px;
}

.gpes-resolution .text-wrapper-6 {
  color: #5d7329;
  
  font-size: 50px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.gpes-resolution .text-wrapper-7 {
  color: #bf9f50;
  
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.gpes-resolution .kepsorozat {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  position: relative;
  width: 100%;
}

.gpes-resolution .kepcontainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 444px;
  justify-content: center;
  position: relative;
  width: 40%;
}

.gpes-resolution .kep {
  align-self: stretch;
  background-image: url('../../img3/kep-2b.JPG');
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.gpes-resolution .kep-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  padding: 0px 20px;
  position: relative;
  padding-right: 0;
}

.gpes-resolution .kep-2 {
  align-self: stretch;
  background-image: url('../../img3/kep-1b.JPG');
  background-position: 50% 50%;
  background-size: cover;
  height: 442px;
  position: relative;
  width: 100%;
  transform: translateY(2px);
}

.gpes-resolution .kepsorozat-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 20px;
  justify-content: center;
  padding: 0px 20px 0px 0px;
  position: relative;
  width: 100%;
}

.gpes-resolution .div-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  height: 444px;
  justify-content: center;
  position: relative;
}

.gpes-resolution .kep-3 {
  align-self: stretch;
  background-image: url('../../img3/kep-3b.JPG');
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.gpes-resolution .kepcontainer-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  position: relative;
}

.gpes-resolution .kep-4 {
  align-self: stretch;
  background-image: url('../../img2/kep-4b.jpg');
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.gpes-resolution .div-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  height: 445px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.gpes-resolution .kepcontainer-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 444px;
  justify-content: center;
  position: relative;
  width: 400px;
}

.gpes-resolution .kep-5 {
  align-self: stretch;
  background-image: url('../../img3/kep-5b.JPG');
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.gpes-resolution .kepcontainer-4 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  height: 475px;
  margin-bottom: -30px;
  padding: 0px 20px;
  position: relative;
  padding-right: 0;
}

.gpes-resolution .kep-6 {
  align-self: stretch;
  background-image: url('../../img3/kep-6b.JPG');
  background-position: 50% 50%;
  background-size: cover;
  height: 444px;
  position: relative;
  width: 100%;
}

.gpes-resolution .kepsorozat-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  height: 445px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.gpes-resolution .kepcontainer-5 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  height: 444px;
  position: relative;
}

.gpes-resolution .kep-7 {
  align-self: stretch;
  background-image: url('../../img3/kep-7b.JPG');
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.gpes-resolution .kepcontainer-6 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  height: 444px;
  padding: 0px 20px;
  position: relative;
  padding-right: 0;

}

.gpes-resolution .kep-8 {
  align-self: stretch;
  background-image: url('../../img3/kep-8b.JPG');
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.gpes-resolution .kep-9 {
  align-self: stretch;
  background-image: url('../../img3/kep-10b.JPG');
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.gpes-resolution .kep-10 {
  align-self: stretch;
  background-image: url('../../img3/kep-9b.JPG');
  background-position: 50% 50%;
  background-size: cover;
  height: 444px;
  position: relative;
  width: 100%;
}

.gpes-resolution .kepcontainer-7 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  height: 444px;
  position: relative;
}

.gpes-resolution .kep-11 {
  align-self: stretch;
  background-image: url('../../img3/kep-11b.JPG');
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.gpes-resolution .kepcontainer-8 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 444px;
  padding: 0px 20px;
  position: relative;
  width: 397px;
  padding-right: 0;

}

.gpes-resolution .kep-12 {
  align-self: stretch;
  background-image: url('../../img3/kep-12b.JPG');
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.gpes-resolution .kep-13 {
  align-self: stretch;
  background-image: url('../../img3/kep-13b.JPG');
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.gpes-resolution .kep-14 {
  align-self: stretch;
  background-image: url('../../img2/kep-14b.jpg');
  background-position: 50% 50%;
  background-size: cover;
  height: 444px;
  position: relative;
  width: 100%;
}

.gpes-resolution .videodiv {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  position: relative;
  width: 100%;
}

.gpes-resolution .video {
  align-self: stretch;
  background-color: #000000;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.gpes-resolution .footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 21px;
  justify-content: center;
  left: 12%;
  position: relative;
  top: 350px;
  width: 70%;
}

.gpes-resolution .footercontainer {
  align-self: stretch;
  background-color: #ffffff;
  height: 464px;
  position: relative;
  width: 100%;
}

.gpes-resolution .footerheadingcont {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 152px;
  justify-content: center;
  left: 126px;
  padding: 24px 0px;
  position: absolute;
  top: 0;
  width: 228px;
}

.gpes-resolution .footer-2 {
  align-items: center;
  display: flex;
  left: 0;
  padding: 0px 56px;
  position: absolute;
  top: 152px;
  width: 100%;
}

.gpes-resolution .footerjobbcontainer {
  height: 282px;
  overflow: hidden;
  position: relative;
  width: 45%;
}

.gpes-resolution .footer-cuccok {
  height: 283px;
  left: 77px;
  position: relative;
  width: 260px;
}

.gpes-resolution .text-wrapper-8 {
  color: #000000;
  left: 11px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 37px;
}

.gpes-resolution .text-wrapper-9 {
  color: #000000;
  
  font-size: 20px;
  font-weight: 400;
  height: 19px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 71px;
}

.gpes-resolution .e-mail-info {
  color: #000000;
  
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 105px;
}

.gpes-resolution .span {
  color: #000000;
  
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
}

.gpes-resolution .text-wrapper-10 {
  text-decoration: underline;
}

.gpes-resolution .socialmediadiv {
  height: 52px;
  left: 0;
  position: absolute;
  top: 160px;
  width: 146px;
}

.gpes-resolution .element-zalakaros-szent {
  color: #000000;
  
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 206px;
}

.gpes-resolution .map {
  flex: 1;
  flex-grow: 1;
  height: 282px;
  position: relative;
}

.gpes-resolution .rectangle {
  height: 282px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 85%;
}

.gpes-resolution .frame-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.gpes-resolution .div-6 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  gap: 120px;
  height: 19px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.gpes-resolution .text-wrapper-11 {
  color: #000000;
  
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-decoration: underline;
  width: fit-content;
}

.gpes-resolution .licenszdiv {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  gap: 10px;
  height: 29px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.gpes-resolution .licensz {
  color: #000000;
  flex: 1;
  
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 10px;
  position: relative;
  text-align: center;
}

.gpes-resolution .kartyakdiv {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
  transform: translateY(-10px);
}

.gpes-resolution .kartyak {
  height: 21px;
  object-fit: cover;
  position: relative;
  width: 150px;
}
/* Hide the .navvonal elements by default */
.navvonal, .navvonal-2, .navvonal-3{
  display: none; /* Hide by default */
}

/* Show the .navvonal elements when hovering over the .text-wrapper-3 elements */
.kezdolapwrap:hover .navvonal,
.galeriawrap:hover .navvonal-2,
.kapcsolatwrap:hover .navvonal-3,
.arainkwrap:hover .navvonal-3,
.foglalaswrap:hover .navvonal-3,
.informaciowrap:hover .navvonal-3 {
  display: block; /* Show when hovering over the corresponding wrapper */
}
.cpnavvonal, .cpnavvonal-2, .cpnavvonal-3{
  display: none; /* Hide by default */
}

/* Show the .navvonal elements when hovering over the .text-wrapper-3 elements */
.cpkezdolapwrap:hover .cpnavvonal,
.cpgaleriawrap:hover .cpnavvonal-2,
.cpkapcsolatwrap:hover .cpnavvonal-2,
.cparainkwrap:hover .cpnavvonal-2,
.cpfoglalaswrap:hover .cpnavvonal-3,
.cpinformaciowrap:hover .cpnavvonal-2 {
  display: block; /* Show when hovering over the corresponding wrapper */
}
.gpnavvonal, .gpnavvonal-2, .gpnavvonal-3{
  display: none; /* Hide by default */
}

/* Show the .navvonal elements when hovering over the .text-wrapper-3 elements */
.gpkezdolapwrap:hover .gpnavvonal,
.gpgaleriawrap:hover .gpnavvonal-2,
.gpkapcsolatwrap:hover .gpnavvonal-2,
.gparainkwrap:hover .gpnavvonal-2,
.gpfoglalaswrap:hover .gpnavvonal-4,
.gpinformaciowrap:hover .gpnavvonal-2 {
  display: block; /* Show when hovering over the corresponding wrapper */
}
.mmnavvonal, .mmnavvonal-2, .mmnavvonal-3{
  display: none; /* Hide by default */
}

/* Show the .navvonal elements when hovering over the .text-wrapper-3 elements */
.mmkezdolapwrap:hover .mmnavvonal,
.mmgaleriawrap:hover .mmnavvonal-2,
.mmkapcsolatwrap:hover .mmnavvonal-2,
.mmarainkwrap:hover .mmnavvonal-2,
.mmfoglalaswrap:hover .mmnavvonal-4,
.mminformaciowrap:hover .mmnavvonal-2 {
  display: block; /* Show when hovering over the corresponding wrapper */
}

/* General styles for smooth transitions */
.cystal, .crystalkep,.crystalkep-2, .crystalkep-3, .frame-4, .frame-5, .frame-6, .szimbolumok img {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effects for the .cystal div */
.cystal:hover {
  transform: scale(1.05);
  /*box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);*/
}

/* Hover effects for the frame images */
.frame-4:hover, .frame-5:hover, .frame-6:hover {
  transform: scale(1.1);
}

/* Hover effects for the symbol images */
.icon-wrapper {
  position: relative;
  display: inline-block;
}

.szimbolumok img {
  display: block;
}

.szimbolumok img:hover {
  transform: scale(1.2);
}

/* Tooltip styling */
.tooltip {
  visibility: hidden;
  width: max-content;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the icon */
  left: 67%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;
  transform: translate(-50%, 220%);
}

.icon-wrapper:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/* Additional effects for separation lines */
.elvalasztovonal {
  transition: background-color 0.3s ease;
}

.szimbolumok:hover .elvalasztovonal {
  background-color: #ffcc00;
}
.gpes-resolution .frame-7 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}
/*ScroolInWiew*/
.gpes-resolution .overlap-group-2,
.gpes-resolution .galeriaheadingwrap,
.gpes-resolution .footerheadingcont {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.gpes-resolution .overlap-group-2.animate,
.gpes-resolution .galeriaheadingwrap.animate,
.gpes-resolution .footerheadingcont.animate {
  opacity: 1;
  transform: translateY(0);
}

/* Specific styles for overlap-group-2 */

.gpes-resolution .vonal {
  background-color: #bf9f50;
  height: 3px;
  position: absolute;
  width: 0;
  transition: width 1s ease-out;
}

.gpes-resolution .overlap-group-2.animate .vonal {
  width: 71px;
}

.gpes-resolution .h-zaink-head {
  color: #5d7329;
  font-size: 50px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 1s ease-out 0.5s, transform 1s ease-out 0.5s;
}

.gpes-resolution .overlap-group-2.animate .h-zaink-head {
  opacity: 1;
  transform: translateX(0);
}

/* Specific styles for galeriaheadingwrap */
.gpes-resolution .galeriaheadingwrap {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 7px;
  height: 135px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.gpes-resolution .vonal-2 {
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 0;
  transition: width 1s ease-out;
}

.gpes-resolution .galeriaheadingwrap.animate .vonal-2 {
  width: 57px;
}

.gpes-resolution .footerheadingcont.animate .vonal-2 {
  width: 57px;
}

.gpes-resolution .text-wrapper-6 {
  color: #5d7329;
  font-size: 50px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 1s ease-out 0.5s, transform 1s ease-out 0.5s;
}

.gpes-resolution .galeriaheadingwrap.animate .text-wrapper-6 {
  opacity: 1;
  transform: scale(1);
}

.gpes-resolution .footerheadingcont.animate .text-wrapper-6 {
  opacity: 1;
  transform: scale(1);
}