.iphoneinfo {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  height: 4800px;
}
.div-32 p {
  margin-left: 0;
}

.iphoneinfo .div-29 {
  background-color: #ffffff;
  height: 4214px;
  position: relative;
  width: 100vw;
}

.iphoneinfo .herowrap-2 {
  height: 932px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
}

.iphoneinfo .overlap-group-10 {
  height: 932px;
  position: relative;
}

.iphoneinfo .hero-6 {
  background-image: url(../../img2/hero-8.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 932px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
}

.iphoneinfo .logo-5 {
  background-position: 50% 50%;
  background-size: cover;
  height: 76px;
  z-index: 1000;
  left: 20px;
  position: relative;
  width: 42px;
  top:20px;
}

.iphoneinfo .herocontentcont-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  height: 736px;
  left: 0;
  padding: 236px 26px;
  position: absolute;
  top: 196px;
  width: 100vw;
}

.iphoneinfo .kuruk-hill-head-7 {
  color: white;
  
  font-size: 50px;
  font-weight: 400;
  height: 53px;
  letter-spacing: 0;
  line-height: normal;
  margin-right: -3px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 381px;
}

.iphoneinfo .text-wrapper-81 {
  color: white;
  
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.iphoneinfo .hambimenu-3 {
  height: 60px;
  left: 351px;
  position: absolute;
  top: 24px;
  width: 60px;
}

.iphoneinfo .nyelvek-9 {
  align-items: center;
  border-radius: 5px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  left: 229px;
  overflow: hidden;
  position: absolute;
  top: 28px;
}

.iphoneinfo .div-30 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.iphoneinfo .img-24 {
  height: 30px;
  position: relative;
  width: 30px;
}

.iphoneinfo .text-wrapper-82 {
  color: #ffffff;
  
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.iphoneinfo .angol-9 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 34.04px;
  position: relative;
  width: 100%;
}

.iphoneinfo .frame-96 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  height: 3282px;
  left: 0;
  padding: 30px 0px;
  position: absolute;
  top: 932px;
  width: 100%;
}

.iphoneinfo .container-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  position: relative;
  width: 100vw;
}

.iphoneinfo .miniinfo {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 0px 35px;
  position: relative;
  width: 100vw;
}

.iphoneinfo .infoahazrolcontainer {
  align-self: stretch;
  background-color: #5d7329;
  border-radius: 5px;
  height: 675px;
  overflow: hidden;
  position: relative;
  width: 90%;
  margin-left: 5%;
}

.iphoneinfo .infoheadingwrap {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 1px;
  left: 20px;
  position: absolute;
  top: 26px;
}

.iphoneinfo .div-31 {
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 71px;
}

.iphoneinfo .text-wrapper-83 {
  color: #ffffff;
  
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.iphoneinfo .div-32 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 11px;
  left: 20px;
  padding: 18px 0px;
  position: absolute;
  top: 57px;
}

.iphoneinfo .text-wrapper-84 {
  color: #ffffff;
  
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 317px;
}

.iphoneinfo .text-wrapper-85 {
  color: #ffffff;
  
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 317px;
}

.iphoneinfo .text-wrapper-86 {
  color: #ffffff;
  top: 620px;
    
  font-size: 15px;
  font-weight: 400;
  left: 20px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  width: 317px;
}

.iphoneinfo .tilosikonok-2 {
  height: 40px;
  left: 20px;
  position: absolute;
  top: 550px;
  width: 162px;
}

.iphoneinfo .paramter-2 {
  align-items: flex-start;
  background-color: #5d7329;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  height: 1560px;
  overflow: hidden;
  padding: 26px 0;
  position: relative;
  width: 90%;
  min-height: 1550px;
}
.iphoneinfo .paramter-2 div,p {
  margin-left: 10px;
}


.iphoneinfo .div-33 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.iphoneinfo .div-34 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  position: relative;
}

.iphoneinfo .szolgaltatasokcont-2 {
  align-items: flex-start;
  background-color: #5d7329;
  border-radius: 5px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 18px;
  overflow: hidden;
  padding: 26px 0;
  position: relative;
  width: 90%;
  min-height: 1000px;
}
.iphoneinfo .szolgaltatasokcont-2 div,p {
  margin-left: 10px;
}

.iphoneinfo .div-35 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 18px;
  position: relative;
}

.iphoneinfo .arainkcont-2 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 15px;
  height: 329px;
  position: relative;
}

.iphoneinfo .text-wrapper-87 {
  color: #ffffff;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-decoration: underline;
  width: 317px;
}

.iphoneinfo .green-passion-green-2 {
  color: #ffffff;
  
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -41px;
  position: relative;
  width: 317px;
}

.iphoneinfo .div-36 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 11px;
  position: relative;
  transform: translateY(70px);
}

.iphoneinfo .foglalasicont-wrapper {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 0px 35px 10px;
  position: relative;
  width: 100vw;
}

.iphoneinfo .foglalasicont-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #5d7329;
  border-radius: 5px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
  padding: 26px 0;
  position: relative;
  width: 90%;
  margin-left: 5%;
}
.iphoneinfo .foglalasicont-2 div,p,img {
  margin-left: 10px;
}

.iphoneinfo .foglalasicontentwrap {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 11px;
  padding: 18px 0px;
  position: relative;
}

.iphoneinfo .pdffilegomb-2 {
  height: 30px;
  position: relative;
  width: 76px;
}

.iphoneinfo .foglalasgomb-wrapper-2 {
  align-items: center;
  display: flex;
  gap: 10px;
  height: 100px;
  justify-content: center;
  overflow: hidden;
  padding: 10px;
  position: relative;
  width: 100vw;
  flex-direction: column;
}

.iphoneinfo .foglalasgomb-2 {
  align-items: center;
  background-color: #5d7329;
  border-radius: 5px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  margin-bottom: -12px;
  margin-top: -12px;
  overflow: hidden;
  padding: 14px 27px;
  position: relative;
}

.iphoneinfo .text-wrapper-88 {
  color: #ffffff;
  
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}
