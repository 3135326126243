.iphonemagicmango {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.iphonemagicmango .overlap-group-wrapper-2 {
  background-color: #ffffff;
  height: auto;
  width: 95vw;
}

.iphonemagicmango .overlap-group-6 {
  height: auto;
}

.iphonemagicmango .crystalcontainer-2 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 0;
}

.iphonemagicmango .herokep-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.iphonemagicmango .logo-wrapper {
  background-image: url(../../img2/hero-7.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 91vh;
  position: relative;
  width: 100vw;
}

.iphonemagicmango .logo-2 {
  background-position: 50% 50%;
  background-size: cover;
  height: 76px;
  z-index: 1000;
  left: 20px;
  position: relative;
  width: 42px;
  top:20px;
}

.iphonemagicmango .frame-91 {
  height: 736px;
  left: 0;
  position: absolute;
  top: 5vh;
  width: 100vw;
}

.iphonemagicmango .kuruk-hill-head-5 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 191, 52) 100%);
  background-clip: text;
  color: transparent;
  
  font-size: 50px;
  font-weight: 400;
  left: 26px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-fill-color: transparent;
  top: 235px;
  white-space: nowrap;
  width: 381px;
}

.iphonemagicmango .text-wrapper-42 {
  color: white;
  
  font-size: 30px;
  font-weight: 400;
  left: 26px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 305px;
}

.iphonemagicmango .sliderwrap {
  height: 50px;
  left: 42px;
  position: absolute;
  top: 615px;
  width: 353px;
}

.iphonemagicmango .hambimenu {
  height: 60px;
  left: 351px;
  position: absolute;
  top: 24px;
  width: 60px;
}

.iphonemagicmango .div-10 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 1313px;
  position: relative;
  width: 100vw;
}

.iphonemagicmango .div-11 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 80%;
    margin-left: 7.5%;
}

.iphonemagicmango .magicmangowrap {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.iphonemagicmango .text-wrapper-43 {
  color: #000000;
  
  font-size: 33px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.iphonemagicmango .magicmangovonal {
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 191, 52) 100%);
  border-radius: 20px;
  height: 3px;
  position: relative;
  width: 207px;
}

.iphonemagicmango .ferohely-6 {
  align-items: center;
  background-color: #5d7329;
  border-radius: 15px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  height: 24px;
  overflow: hidden;
  padding: 1px 14px;
  position: relative;
  transform: translateX(30px);
}

.iphonemagicmango .text-wrapper-44 {
  color: #ffffff;
  
  font-size: 19px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -2px;
  position: relative;
  width: fit-content;
}

.iphonemagicmango .icon-user-6 {
  height: 20px;
  margin-right: -0.5px;
  position: relative;
  width: 20px;
}

.iphonemagicmango .szoveg {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 30px 0px 10px;
  position: relative;
  width: 359px;
}

.iphonemagicmango .h-l-szob-ban-egy-6 {
  color: #000000;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 359px;
}

.iphonemagicmango .frame-92 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.iphonemagicmango .ikonok {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 359px;
}

.iphonemagicmango .ikonok-2 {
  align-items: center;
  display: flex;
  height: 37px;
  justify-content: space-between;
  padding: 8px 0px;
  position: relative;
  width: 270px;
}

.iphonemagicmango .div-12 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  margin-bottom: -2px;
  margin-top: -2px;
  padding: 0px 1px;
  position: relative;
}

.iphonemagicmango .img-17 {
  height: 25px;
  position: relative;
  width: 25px;
}

.iphonemagicmango .text-wrapper-45 {
  color: #000000;
  
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.iphonemagicmango .div-13 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  margin-bottom: -2px;
  margin-top: -2px;
  position: relative;
}

.iphonemagicmango .ikonok-content {
  align-items: center;
  display: flex;
  height: 37px;
  justify-content: space-between;
  padding: 8px 0px;
  position: relative;
  width: 310px;
}

.iphonemagicmango .div-14 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  height: 25px;
  margin-bottom: -2px;
  margin-top: -2px;
  padding: 10px 0px;
  position: relative;
}

.iphonemagicmango .img-18 {
  height: 25px;
  margin-bottom: -10px;
  margin-top: -10px;
  position: relative;
  width: 25px;
}

.iphonemagicmango .text-wrapper-46 {
  color: #000000;
  
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -6px;
  margin-top: -8px;
  position: relative;
  width: fit-content;
}

.iphonemagicmango .ikonok-cont {
  align-items: center;
  display: flex;
  height: 37px;
  justify-content: space-between;
  padding: 8px 0px;
  position: relative;
  width: 315px;
}

.iphonemagicmango .reggelicont-wrapper {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 90px;
  height: 37px;
  padding: 8px 0px;
  position: relative;
}

.iphonemagicmango .text-wrapper-47 {
  color: #000000;
  flex: 1;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.iphonemagicmango .gombwrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 7px;
  height: auto;
  justify-content: center;
  padding: 10px;
  position: relative;
  width: 359px;
}

.iphonemagicmango .gombcont {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7px;
  justify-content: center;
  margin-bottom: -6.5px;
  margin-top: -6.5px;
  position: relative;
}

.iphonemagicmango .gombvonal {
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 106px;
}

.iphonemagicmango .gomb {
  align-items: center;
  background-color: #5d7329;
  border-radius: 5px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
  padding: 10px;
  position: relative;
}

.iphonemagicmango .text-wrapper-48 {
  color: #ffffff;
  
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.iphonemagicmango .nyelvek-6 {
  align-items: center;
  border-radius: 5px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  left: 229px;
  overflow: hidden;
  position: absolute;
  top: 28px;
}

.iphonemagicmango .div-15 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.iphonemagicmango .img-19 {
  height: 30px;
  position: relative;
  width: 30px;
}

.iphonemagicmango .text-wrapper-49 {
  color: #ffffff;
  
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.iphonemagicmango .angol-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 34.04px;
  position: relative;
  width: 100%;
}
