.iphone-pro-max {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.iphone-pro-max .div-45 {
  background-color: #ffffff;
    height: auto;
    width: 100vw;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    top: 0;
}

.iphone-pro-max .herocontainer {
  align-items: flex-start;
  display: inline-flex;
  gap: 10px;
  left: 0;
  position: relative;
  top: 0;
}

.iphone-pro-max .hero-8 {
  background-image: url(../../img2/hero-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 100vh;
  position: relative;
  width: 100vw;
}

.iphone-pro-max .logo-7 {
  background-position: 50% 50%;
  background-size: cover;
  height: 76px;
  z-index: 1000;
  left: 20px;
  position: absolute;
  top: 0;
  width: 42px;
  top:20px;
}

.iphone-pro-max .nyelvek-11 {
  align-items: center;
  border-radius: 5px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  left: 229px;
  overflow: hidden;
  position: absolute;
  top: 28px;
}

.iphone-pro-max .div-46 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.iphone-pro-max .img-26 {
  height: 30px;
  position: relative;
  width: 30px;
}

.iphone-pro-max .text-wrapper-101 {
  color: #ffffff;
  
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.iphone-pro-max .angol-11 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 34.04px;
  position: relative;
  width: 100%;
}

.iphone-pro-max .herocontentwrap-3 {
  align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 17px;
    left: 0;
    padding: 33vh 0px 0px 26px;
    position: absolute;
    top: 10vh;
    width: 100vw;
    overflow: hidden;
    opacity: 0;
    transform: translateY(50px) scale(0.8);
    transition: opacity 1s ease-out, transform 1s ease-out;
}
.iphone-pro-max .herocontentwrap-3.animate {
  opacity: 1;
  transform: translateY(0) scale(1);
}
.iphone-pro-max .hazainkheadingwrap,
.iphone-pro-max .galeriaheadwrap,
.iphone-pro-max .kapcsolatwrap-3 {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}
.iphone-pro-max .hazainkheadingwrap.animate,
.iphone-pro-max .galeriaheadwrap.animate,
.iphone-pro-max .kapcsolatwrap-3.animate {
  opacity: 1;
  transform: translateY(0);
}
.iphone-pro-max .div-50,
.iphone-pro-max .headingvonal {
  transition: width 1s ease-out;
}
.iphone-pro-max .hazainkhead,
.iphone-pro-max .text-wrapper-106,
.iphone-pro-max .text-wrapper-114 {
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 1s ease-out 0.5s, transform 1s ease-out 0.5s;
}
.iphone-pro-max .hazainkheadingwrap.animate .hazainkhead,
.iphone-pro-max .galeriaheadwrap.animate .text-wrapper-106,
.iphone-pro-max .kapcsolatwrap-3.animate .text-wrapper-114 {
  opacity: 1;
  transform: translateX(0);
}
.iphone-pro-max .headingvonal,
.iphone-pro-max .div-50 {
  width: 0;
  transition: width 1s ease-out;
}
.iphone-pro-max .hazainkheadingwrap.animate .headingvonal,
.iphone-pro-max .galeriaheadwrap.animate .div-50 {
  width: 57px;
}

.iphone-pro-max .kuruk-hill-head-9 {
  color: white;
  font-size: 50px;
  font-weight: 400;
  height: 53px;
  letter-spacing: 0;
  line-height: normal;
  margin-right: -3px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 381px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8), /* 3D effect */
               -2px -2px 4px rgba(0, 0, 0, 0.8); /* 3D effect */
  outline-offset: -2px; /* Adjust outline offset */
}

.iphone-pro-max .text-wrapper-102 {
  color: white;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8), /* 3D effect */
               -2px -2px 4px rgba(0, 0, 0, 0.8); /* 3D effect */
  outline-offset: -2px; /* Adjust outline offset */
}

.iphone-pro-max .mer-ljetek-el-a-k-2 {
  color: #ffffff;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8), /* 3D effect */
               -2px -2px 4px rgba(0, 0, 0, 0.8); /* 3D effect */
  outline-offset: -2px; /* Adjust outline offset */
  margin: 0;
}


.iphone-pro-max .gomb-4 {
  align-items: center;
  background-color: rgba(93, 115, 41, 1);
  border-radius: 10px;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  overflow: hidden;
  padding: 10px 30px;
  position: relative;
}

.iphone-pro-max .text-wrapper-103 {
  color: white;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.iphone-pro-max .hambimenu-4 {
  height: 60px;
  right: 10px;
  position: absolute;
  top: 24px;
  width: 60px;
}

.iphone-pro-max .hazainkwrap-2 {
  height: auto;
  left: 0;
  position: relative;
  width: 100vw;
}

.iphone-pro-max .hazainkheadingwrap {
  transform: translateX(2%);
  height: 47px;
    left: 10%;
    position: relative;
    top: 10px;
    width: 80%;
}

.iphone-pro-max .hazainkhead {
  color: #5d7329;
  
  font-size: 30px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 8px;
}

.iphone-pro-max .headingvonal {
  background-color: #bf9f50;
  height: 3px;
  left: 2px;
  position: absolute;
  top: 0;
  width: 48px;
}

.iphone-pro-max .hazainkcontentwrap-2 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 17px;
  left: auto;
  position: relative;
  margin-top: 30px;
  width: 88%;
    margin-left: 6%;
}

.iphone-pro-max .szoveg-6 {
  color: #000000;
  
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.iphone-pro-max .szoveg-7 {
  color: #000000;
  
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.iphone-pro-max .szoveg-8 {
  color: #000000;
  
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width:fit-content;
}

.iphone-pro-max .szolodiszdiv {
  align-items: center;
    display: flex;
    gap: 16px;
    justify-content: center;
    left: 10%;
    position: relative;
    margin-top: 3%;
    margin-bottom: 3%;
    width: 80%;
}

.iphone-pro-max .diszvonal-2 {
  background-color: #bf9f50;
  flex: 1;
  flex-grow: 1;
  height: 2px;
  position: relative;
}

.iphone-pro-max .szolo-2 {
  background-image: url(../../img2/szolo-2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  position: relative;
  width: 40px;
}

.iphone-pro-max .hazakcontainer-2 {
  align-items: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    left: 0;
    position: relative;
    margin-top: 20px;
    width: 100vw;
}

.iphone-pro-max .div-47 {
  align-items: flex-start;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 273px;
  justify-content: space-between;
  margin-left: -28px;
  margin-right: -28px;
  overflow: hidden;
  position: relative;
  width: 360px;
}

.iphone-pro-max .div-48 {
  align-self: stretch;
  background-image: url(../../img2/crystalpowerkep.png);
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}
.iphone-pro-max .div-48-mm {
  align-self: stretch;
  background-image: url(../../img2/crystalkep2.png);
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}
.iphone-pro-max .div-48-gp {
  align-self: stretch;
  background-image: url(../../img2/crystalkep3.png);
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.iphone-pro-max .div-wrapper-5 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 10px 0px;
  position: relative;
  width: 100%;
}

.iphone-pro-max .div-49 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
}

.iphone-pro-max .text-wrapper-104 {
  color: #5d7329;
  
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 228px;
}

.iphone-pro-max .ferohely-9 {
  align-items: center;
  background-color: #5d7329;
  border-radius: 15px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  height: 24px;
  overflow: hidden;
  padding: 1px 14px;
  position: relative;
}

.iphone-pro-max .text-wrapper-105 {
  color: #ffffff;
  
  font-size: 19px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -2px;
  position: relative;
  width: fit-content;
}

.iphone-pro-max .icon-user-9 {
  height: 18px;
  margin-right: -0.5px;
  position: relative;
  width: 18px;
  transform: translate(-2px, -1px);
}

.iphone-pro-max .img-27 {
  align-self: stretch;
  height: 25px;
  position: relative;
  width: 100%;
}

.iphone-pro-max .galeriawrap-4 {
  height: 1300px;
  left: 0;
  position: relative;
  width: 100vw;
  margin-top: 40px;
}

.iphone-pro-max .kepsorozat-2 {
  align-items: center;
  display: flex;
  gap: 10px;
  left: 7.5%;
  position: absolute;
  top: 78px;
  width: 85%;
}

.iphone-pro-max .kep-15 {
  background-image: url(../../img3/kep-1b.JPG);
  background-position: 50% 50%;
  background-size: cover;
  height: 129px;
  position: relative;
  width: 91px;
}

.iphone-pro-max .kep-16 {
  background-image: url(../../img3/kep-2b.JPG);
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  height: 126px;
  position: relative;
}

.iphone-pro-max .kepsorozat-3 {
  align-items: center;
  display: flex;
  gap: 10px;
  left: 7.5%;
  position: absolute;
  top: 217px;
  width: 85%;
}

.iphone-pro-max .kep-17 {
  background-image: url(../../img3/kep-3b.JPG);
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  height: 129px;
  position: relative;
}

.iphone-pro-max .kep-18 {
  background-image: url(../../img2/kep-4b.jpg);
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  height: 126px;
  position: relative;
}

.iphone-pro-max .kepsorozat-4 {
  align-items: center;
  display: flex;
  gap: 10px;
  left: 7.5%;
  position: absolute;
  top: 356px;
  width: 85%;
}

.iphone-pro-max .kep-19 {
  background-image: url(../../img3/kep-5b.JPG);
  background-position: 50% 50%;
  background-size: cover;
  height: 129px;
  position: relative;
  width: 91px;
}

.iphone-pro-max .kep-20 {
  background-image: url(../../img3/kep-6b.JPG);
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  height: 126px;
  position: relative;
}

.iphone-pro-max .kepsorozat-5 {
  align-items: center;
  display: flex;
  gap: 10px;
  left: 7.5%;
  position: absolute;
  top: 495px;
  width: 85%;
}

.iphone-pro-max .kep-21 {
  background-image: url(../../img3/kep-7b.JPG);
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  height: 126px;
  position: relative;
}

.iphone-pro-max .kep-22 {
  background-image: url(../../img3/kep-8b.JPG);
  background-position: 50% 50%;
  background-size: cover;
  height: 129px;
  position: relative;
  width: 91px;
}

.iphone-pro-max .kepsorozat-6 {
  align-items: center;
  display: flex;
  gap: 10px;
  left: 7.5%;
  position: absolute;
  top: 634px;
  width: 85%;
}

.iphone-pro-max .kep-23 {
  background-image: url(../../img3/kep-10b.JPG);
  background-position: 50% 50%;
  background-size: cover;
  height: 129px;
  position: relative;
  width: 91px;
}

.iphone-pro-max .kep-24 {
  background-image: url(../../img3/kep-9b.JPG);
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  height: 126px;
  position: relative;
}

.iphone-pro-max .kepsorozat-7 {
  align-items: center;
  display: flex;
  gap: 10px;
  left: 7.5%;
  position: absolute;
  top: 773px;
  width: 85%;
}

.iphone-pro-max .kep-25 {
  background-image: url(../../img3/kep-11b.JPG);
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  height: 126px;
  position: relative;
}

.iphone-pro-max .kep-26 {
  background-image: url(../../img3/kep-12b.JPG);
  background-position: 50% 50%;
  background-size: cover;
  height: 129px;
  position: relative;
  width: 91px;
}

.iphone-pro-max .kepsorozat-8 {
  align-items: center;
  display: flex;
  gap: 10px;
  left: 7.5%;
  position: absolute;
  top: 912px;
  width: 85%;
}

.iphone-pro-max .kep-27 {
  background-image: url(../../img3/kep-13b.JPG);
  background-position: 50% 50%;
  background-size: cover;
  height: 129px;
  position: relative;
  width: 91px;
}

.iphone-pro-max .kep-28 {
  background-image: url(../../img2/kep-14b.jpg);
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  height: 126px;
  position: relative;
}

.iphone-pro-max .videodiv {
  align-items: center;
  display: flex;
  gap: 10px;
  height: 129px;
  left: 7.5%;
  position: absolute;
  top: 1051px;
  width: 85%;
}

.iphone-pro-max .video-2 {
  align-self: stretch;
  background-color: #000000;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.iphone-pro-max .galeriaheadwrap {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 35px;
  position: absolute;
  top: 4px;
}

.iphone-pro-max .div-50 {
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 52px;
}

.iphone-pro-max .text-wrapper-106 {
  color: #5d7329;
  
  font-size: 30px;
  font-weight: 400;
  height: 31px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: 101px;
}

.iphone-pro-max .text-wrapper-107 {
  color: #bf9f50;
  
  font-size: 15px;
  font-weight: 400;
  height: 17px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: 120px;
}

.iphone-pro-max .footerwrap-2 {
  height: 740px;
  left: 0;
  position: relative;
  width: 100vw;
  margin-top: 0;
}

.iphone-pro-max .text-wrapper-108 {
  color: #000000;
  
  font-size: 15px;
  font-weight: 400;
  left: 9.8%;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 113px;
}

.iphone-pro-max .text-wrapper-109 {
  color: #000000;
  
  font-size: 15px;
  font-weight: 400;
  left: 7.5%;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 141px;
}

.iphone-pro-max .text-wrapper-110 {
  color: #000000;
  
  font-size: 15px;
  font-weight: 400;
  left: 9.8%;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 177px;
  margin: 10px 0 0 0;
}

.iphone-pro-max .element-zalakros-szent-2 {
  color: #000000;
  
  font-size: 15px;
  font-weight: 400;
  left: 7.5%;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 263px;
}

.iphone-pro-max .div-51 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 9px;
  left: 7.5%;
  position: absolute;
  top: 527px;
  width: 177px;
}

.iphone-pro-max .text-wrapper-111 {
  color: #000000;
  
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-decoration: underline;
  width: fit-content;
}

.iphone-pro-max .text-wrapper-112 {
  color: #000000;
  
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-decoration: underline;
  width: fit-content;
}

.iphone-pro-max .licensz-wrapper {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 657px;
  width: 100vw;
}

.iphone-pro-max .licensz-2 {
  color: #000000;
  
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.iphone-pro-max .text-wrapper-113 {
  color: #000000;
  
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
}

.iphone-pro-max .socialmediadiv {
  height: 50px;
  left: 7.5%;
  position: absolute;
  top: 210px;
  width: 134px;
}

.iphone-pro-max .terkepwrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 177px;
  justify-content: center;
  left: 7.5%;
  position: absolute;
  top: 326px;
  width: 85%;
}

.iphone-pro-max .map-2 {
  align-self: stretch;
  background-image: url(../../img2/rectangle.png);
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.iphone-pro-max .kartyakdiv {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 695px;
  width: 100vw;
}

.iphone-pro-max .kartyakslatt {
  height: 21px;
  object-fit: cover;
  position: relative;
  width: 150px;
}

.iphone-pro-max .kapcsolatwrap-3 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 33px;
  position: absolute;
  top: 34px;
}

.iphone-pro-max .text-wrapper-114 {
  color: #000000;
  
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}
.Phone-navlinkek {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 84px;
  right: 10px;
  z-index: 1000;
}

.Phone-navlinkek > div {
  padding: 10px;
  cursor: pointer;
  color: white;
  transition: color 0.3s, transform 0.3s;
}

.Phone-navlinkek > div:hover {
  /*color: rgba(93, 115, 41, 1);
  transform: translateX(10px);*/
}

.Phone-kezdolapwrap, .Phone-galeriawrap, .Phone-kapcsolatwrap, .Phone-arainkwrap, .Phone-foglalaswrap, .Phone-informaciowrap {
  display: flex;
  align-items: center;
}

.Phone-navvonal-4, .Phone-navvonal-2, .Phone-navvonal-3 {
  margin-right: 10px;
  background-color: white;
  height: 2px;
  flex-grow: 1;
}

.Phone-text-wrapper-3 {
  cursor: pointer;
  /*font-family: 'Arial', sans-serif;*/
  font-size: 18px;
}
.elvalasztovonal {
  background-color: #bf9f50;
  border-radius: 20px;
  height: 22px;
  position: relative;
  width: 2px;
}
/* General styles for smooth transitions */
.cystal, .crystalkep,.crystalkep-2, .crystalkep-3, .frame-4, .frame-5, .frame-6, .szimbolumok img {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effects for the .cystal div */
.cystal:hover {
  transform: scale(1.05);
  /*box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);*/
}

/* Hover effects for the frame images */
.frame-4:hover, .frame-5:hover, .frame-6:hover {
  transform: scale(1.1);
}

/* Hover effects for the symbol images */
.icon-wrapper {
  position: relative;
  display: inline-block;
}

.szimbolumoks img {
  display: block;
}


/* Tooltip styling */
.tooltips {
  visibility: visible;
  width: max-content;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the icon */
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  transition: opacity 0.3s;
  white-space: nowrap;
  transform: translate(-50%, 0%);
}

.icon-wrapper:hover .tooltips {
  visibility: visible;
  opacity: 1;
}

/* Additional effects for separation lines */
.elvalasztovonal {
  transition: background-color 0.3s ease;
}

.szimbolumok:hover .elvalasztovonal {
  background-color: #ffcc00;
}

