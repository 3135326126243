/* Regular Font */
.leaflet-control-attribution,
.leaflet-control-logo {
  display: none !important;
}
.leaflet-bottom {
  display: none !important;
}
@font-face {
  font-family: 'CustomFont';
  src: url('./fonts/GlacialIndifference-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* Bold Font */
@font-face {
  font-family: 'CustomFont';
  src: url('./fonts/GlacialIndifference-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

/* Italic Font */
@font-face {
  font-family: 'CustomFont';
  src: url('./fonts/GlacialIndifference-Italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

html, body {
  overflow-x: hidden;
  /*font-family: 'CustomFont', sans-serif;*/
  /*font-family: 'Comic Sans MS', cursive, sans-serif;*/
  /*font-family: 'Amatic SC', sans-serif;*/
  /*font-family: 'Balsamiq Sans', sans-serif;*/
  font-family: 'Quicksand', sans-serif;
}
body {
  margin: 0px;
}
.language-dropdown {
  position: relative;
  display: inline-block;
  align-items: center;
  border-radius: 5px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  right: 50px;
  position: absolute;
  top: 57px;
}

.dropdown-toggle {
  background-color: rgba(255, 255, 255, 0);
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: white;
}

.dropdown-toggle img {
  margin-right: 10px;
  height: 40px;
    position: relative;
    width: 40px;
}

.dropdown-menu {
  position: absolute;
  background-color: rgba(255, 255, 255, 0);
  z-index: 1;
  right: 0px;
  top: 0px;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: white;
}

.dropdown-item img {
  margin-right: 10px;
  height: 40px;
    position: relative;
    width: 40px;
}

.dropdown-item:hover {
  transform: translateY(-2px);
}
@media only screen and (max-width: 768px) {
  .language-dropdown {
    position: static;
    align-items: center;
    border-radius: 5px;
    display: inline-flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
  }
  .dropdown-menu {
    position: static;
    background-color: rgba(255, 255, 255, 0);
    z-index: 1;
    transform: translateX(40%);
  }
}