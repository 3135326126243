.iphonegreen {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.iphonegreen .passioncontainer-wrapper {
  background-color: #ffffff;
  height: auto;
  width: 100vw;
}

.iphonegreen .passioncontainer {
  height: auto;
}

.iphonegreen .div-5 {
  height: 91vh;
  left: 0;
  position: relative;
  top: 0;
  width: 100vw;
}

.iphonegreen .overlap-group-3 {
  height: 91vh;
  position: relative;
}

.iphonegreen .hero-2 {
  background-image: url(../../img2/hero-7.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 91vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
}

.iphonegreen .frame-33 {
  background-position: 50% 50%;
  background-size: cover;
  height: 76px;
  z-index: 1000;
  left: 20px;
  position: relative;
  width: 42px;
  top:20px;
}

.iphonegreen .frame-34 {
  height: 736px;
  left: 0;
  position: absolute;
  top: 5vh;
  width: 100vw;
}

.iphonegreen .kuruk-hill-head-2 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(93, 115, 41) 100%);
  background-clip: text;
  color: transparent;
  
  font-size: 50px;
  font-weight: 400;
  left: 26px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-fill-color: transparent;
  top: 235px;
  width: 381px;
}

.iphonegreen .text-wrapper-18 {
  color: white;
  
  font-size: 30px;
  font-weight: 400;
  left: 26px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 316px;
}

.iphonegreen .frame-35 {
  height: 50px;
  left: 42px;
  position: absolute;
  top: 615px;
  width: 353px;
}

.iphonegreen .menu-alt-svgrepo-2 {
  height: 60px;
  left: 351px;
  position: absolute;
  top: 24px;
  width: 60px;
}

.iphonegreen .nyelvek-3 {
  align-items: center;
  border-radius: 5px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  left: 229px;
  overflow: hidden;
  position: absolute;
  top: 28px;
}

.iphonegreen .div-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.iphonegreen .img-8 {
  height: 30px;
  position: relative;
  width: 30px;
}

.iphonegreen .text-wrapper-19 {
  color: #ffffff;
  
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.iphonegreen .angol-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 34.04px;
  position: relative;
  width: 100%;
}

.iphonegreen .frame-36 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: auto;
  left: 0;
  position: relative;
  width: 100vw;
}

.iphonegreen .frame-37 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 80%;
    margin-left: 7.5%;
}

.iphonegreen .frame-38 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.iphonegreen .text-wrapper-20 {
  color: #000000;
  
  font-size: 33px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.iphonegreen .frame-39 {
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(93, 115, 41) 100%);
  border-radius: 20px;
  height: 3px;
  position: relative;
  width: 215px;
}

.iphonegreen .ferohely-3 {
  align-items: center;
  background-color: #5d7329;
  border-radius: 15px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  height: 24px;
  overflow: hidden;
  padding: 1px 14px;
  position: relative;
  transform: translateX(30px);
}

.iphonegreen .text-wrapper-21 {
  color: #ffffff;
  
  font-size: 19px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -2px;
  position: relative;
  width: fit-content;
}

.iphonegreen .icon-user-3 {
  height: 20px;
  margin-right: -0.5px;
  position: relative;
  width: 20px;
}

.iphonegreen .frame-40 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 30px 0px 10px;
  position: relative;
  width: 359px;
}

.iphonegreen .h-l-szob-ban-egy-3 {
  color: #000000;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 359px;
}

.iphonegreen .frame-41 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 359px;
}

.iphonegreen .frame-42 {
  align-items: center;
  display: flex;
  height: 37px;
  justify-content: space-between;
  padding: 8px 0px;
  position: relative;
  width: 270px;
}

.iphonegreen .frame-43 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  margin-bottom: -2px;
  margin-top: -2px;
  padding: 0px 1px;
  position: relative;
}

.iphonegreen .img-9 {
  height: 25px;
  position: relative;
  width: 25px;
}

.iphonegreen .text-wrapper-22 {
  color: #000000;
  
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.iphonegreen .frame-44 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  margin-bottom: -2px;
  margin-top: -2px;
  position: relative;
}

.iphonegreen .frame-45 {
  align-items: center;
  display: flex;
  height: 37px;
  justify-content: space-between;
  padding: 8px 0px;
  position: relative;
  width: 310px;
}

.iphonegreen .frame-46 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  height: 25px;
  margin-bottom: -2px;
  margin-top: -2px;
  padding: 10px 0px;
  position: relative;
}

.iphonegreen .img-10 {
  height: 25px;
  margin-bottom: -10px;
  margin-top: -10px;
  position: relative;
  width: 25px;
}

.iphonegreen .text-wrapper-23 {
  color: #000000;
  
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -6px;
  margin-top: -8px;
  position: relative;
  width: fit-content;
}

.iphonegreen .frame-47 {
  align-items: center;
  display: flex;
  height: 37px;
  justify-content: space-between;
  padding: 8px 0px;
  position: relative;
  width: 315px;
}

.iphonegreen .frame-48 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 90px;
  height: 37px;
  padding: 8px 0px;
  position: relative;
}

.iphonegreen .text-wrapper-24 {
  color: #000000;
  flex: 1;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.iphonegreen .frame-49 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 7px;
  height: auto;
  justify-content: center;
  padding: 10px;
  position: relative;
  width: 359px;
}

.iphonegreen .frame-50 {
  background-color: #bf9f50;
  height: 3px;
  margin-top: -6.5px;
  position: relative;
  width: 106px;
}

.iphonegreen .frame-51 {
  align-items: center;
  background-color: #5d7329;
  border-radius: 5px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  margin-bottom: -6.5px;
  overflow: hidden;
  padding: 10px;
  position: relative;
}

.iphonegreen .text-wrapper-25 {
  color: #ffffff;
  
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}
