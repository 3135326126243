.desktop {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
}

.desktop .hero {
  align-self: stretch;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 18%, rgba(0, 0, 0, 0) 100%);
  background-image: url(../../img2/infokep.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 897px;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
}

.desktop .overlap-group {
  height: 897px;
  position: relative;
  width: 100vw;
}

.desktop .herocontentwrap {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 14px;
  height: 745px;
  justify-content: center;
  left: 144px;
  position: absolute;
  top: 152px;
  width: 430px;
}

.desktop .kuruk-hillhead {
  align-self: stretch;
  color: white;
  
  font-size: 75px;
  font-weight: 400;
  height: 75px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
}

.desktop .text-wrapper {
  align-self: stretch;
  color: white;
  
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.desktop .navcont {
  height: 207px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.desktop .logo {
  background-position: 50% 50%;
  background-size: cover;
  height: 90px;
  z-index: 1000;
  left: 20px;
  position: absolute;
  top: 20px;
  width: 51px;
}

.desktop .navlinkek {
  height: 35px;
  left:20%;

  position: absolute;
  top: 65px;
  width: 651px;
}

.desktop .kezdolapwrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 18px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.desktop .navvonal {
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 71px;
}

.desktop .div {
  color: white;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.desktop .galeriawrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 128px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.desktop .navvonal-2 {
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 57px;
}

.desktop .kapcsolatwrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 222px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.desktop .navvonal-3 {
  align-self: stretch;
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 100%;
}

.desktop .arainkwrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 337px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.desktop .foglalaswrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 425px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.desktop .navvonal-4 {
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 64px;
}

.desktop .informaciowrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 526px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.desktop .nyelvek {
  align-items: center;
  border-radius: 5px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  right: 50px;
  overflow: hidden;
  position: absolute;
  top: 57px;
}

.desktop .div-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.desktop .img {
  height: 40px;
  position: relative;
  width: 40px;
}

.desktop .text-wrapper-2 {
  color: white;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.desktop .angol {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 34.04px;
  position: relative;
  width: 100%;
}

.desktop .angolflag {
  height: 34.04px;
  position: relative;
  width: 40px;
}

.desktop .frame {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  padding: 30px 0px;
  position: relative;
  width: 100%;
  max-width: 100vw;
}

.desktop .div-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.desktop .div-wrapper-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
  width: 70%;
  margin-left: 15%;
}

.desktop .infoahazrolcontainer {
  align-items: flex-start;
  align-self: stretch;
  background-color: #5d7329;
  border-radius: 15px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 44px;
  justify-content: center;
  overflow: hidden;
  padding: 33px 49px;
  position: relative;
}

.desktop .infohead {
  color: #ffffff;
  flex: 1;
  
  font-size: 30px;
  font-weight: 400;
  height: 38px;
  letter-spacing: 0;
  line-height: 38.4px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
}

.desktop .infovonal {
  background-color: #bf9f50;
  border-radius: 15px;
  height: 3px;
  left: 0;
  position: absolute;
  top: 0;
  width: 102px;
}

.desktop .frame-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.desktop .text-wrapper-3 {
  align-self: stretch;
  color: #ffffff;
  
  font-size: 19px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.desktop .text-wrapper-4 {
  align-self: stretch;
  color: #ffffff;
  
  font-size: 19px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  margin-left: 10px;
}

.desktop .div-wrapper-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.desktop .text-wrapper-5 {
  color: #ffffff;
  
  font-size: 19px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.desktop .tilosikonok {
  align-self: stretch;
  flex: 0 0 auto;
  position: relative;
  width: 35%;
}

.desktop .parameterekwrap {
  align-items: center;
  align-self: stretch;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;;
}

.desktop .parameterekcont-wrapper {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  position: relative;
  width: 70%;
  margin-left: 15%;
}

.desktop .parameterekcont {
  align-items: flex-start;
  background-color: #5d7329;
  border-radius: 15px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 37px;
  justify-content: center;
  overflow: hidden;
  padding: 46px 49px;
  position: relative;
}

.desktop .parameterekheadcont {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

.desktop .div-3 {
  background-color: #bf9f50;
  border-radius: 15px;
  height: 3px;
  position: relative;
  width: 96px;
}

.desktop .text-wrapper-6 {
  align-self: stretch;
  color: #ffffff;
  
  font-size: 30px;
  font-weight: 400;
  height: 38px;
  letter-spacing: 0;
  line-height: 38.4px;
  position: relative;
  white-space: nowrap;
}

.desktop .h-zainkat-igyekezt {
  color: #ffffff;
  flex: 1;
  
  font-size: 19px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.desktop .div-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 18px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.desktop .div-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 18px;
  position: relative;
  width: 100%;
}

.desktop .szolgaltatasokcont {
  align-items: flex-start;
  align-self: stretch;
  background-color: #5d7329;
  border-radius: 15px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 59px;
  justify-content: center;
  overflow: hidden;
  padding: 46px 53px;
  position: relative;
}

.desktop .overlap-group-wrapper {
  height: 41px;
  position: relative;
  width: 434px;
}

.desktop .overlap-group-2 {
  height: 40px;
  position: relative;
  width: 404px;
}

.desktop .text-wrapper-7 {
  color: #ffffff;
  
  font-size: 30px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 38.4px;
  position: absolute;
  top: 2px;
  white-space: nowrap;
  width: 404px;
}

.desktop .szolgaltatasokvonal {
  background-color: #bf9f50;
  border-radius: 15px;
  height: 3px;
  left: 0;
  position: absolute;
  top: 0;
  width: 103px;
}

.desktop .arainkcont {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.desktop .text-wrapper-8 {
  align-self: stretch;
  color: #ffffff;
  
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-decoration: underline;
}

.desktop .text-wrapper-9 {
  align-self: stretch;
  color: #ffffff;
  
  font-size: 19px;
  font-weight: 400;
  height: 23px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.desktop .foglalasicont {
  align-items: flex-start;
  align-self: stretch;
  background-color: #5d7329;
  border-radius: 15px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 66px;
  justify-content: center;
  overflow: hidden;
  padding: 46px 4.15%;
  position: relative;
}

.desktop .figlalasiheadcont {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.desktop .text-wrapper-10 {
  align-self: stretch;
  color: #ffffff;
  
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 38.4px;
  position: relative;
}

.desktop .foglal-si-fizet-si {
  color: #ffffff;
  flex: 1;
  
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.desktop .pdffilegomb {
  flex: 0 0 auto;
  position: relative;
}

.desktop .foglalasgomb-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 0px 0px 20px;
  position: relative;
  width: 100%;
  flex-direction: column;
}

.desktop .foglalasgomb {
  align-items: center;
  background-color: #5d7329;
  border-radius: 5px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
  padding: 14px 27px;
  position: relative;
}

.desktop .text-wrapper-11 {
  color: #ffffff;
  
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}
.desktop .frame-2 p {
  margin-left: 0;
}
.desktop .div-4 p {
  margin: 0 0 0 10px;
}
.desktop .div-5 .text-wrapper-9 {
  margin-left: 10px;
}
.desktop .div-5 p {
  margin: 0 0 0 10px;
}
