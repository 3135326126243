.magicmango {
  align-items: flex-end;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100vw;
}

.magicmango .herokep {
  align-self: stretch;
  background-image: url(../../img2/herokep.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 897px;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
}

.magicmango .frame {
  height: 100vh;
  position: relative;
  width: 100%;
  background-position: 50% 50%;
  background-size: cover;
  transition: opacity 2s ease-in-out;
}

.magicmango .overlap-group {
  height: 782px;
  left: 1px;
  position: absolute;
  top: 19px;
  width: 100%;
}

.magicmango .herocontentwrap {
  height: 80vh;
  left: 144px;
  position: absolute;
  top: 14vh;
  width: 552px;
  overflow: hidden;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.magicmango .text-wrapper {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, rgb(254, 254, 254) 0%, rgb(255, 191, 52) 100%);
  background-clip: text;
  color: white;
  font-size: 75px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-fill-color: transparent;
  top: 291px;
  white-space: nowrap;
  width: 482px;
  overflow: hidden;
  position: relative;
}

.magicmango .text-wrapper::before {
  content: 'Magic Mango';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 191, 52, 0) 0%,
    rgba(255, 191, 52, 0.5) 50%,
    rgba(255, 191, 52, 0) 100%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s forwards;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.magicmango .div {
  color: white;
  
  font-size: 30px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 381px;
}

.magicmango .navcont {
  height: 207px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.magicmango .logo {
  background-position: 50% 50%;
  background-size: cover;
  height: 90px;
  z-index: 1000;
  left: 20px;
  position: absolute;
  top: 20px;
  width: 51px;
}

.magicmango .navlinkek {
  height: 35px;
  left:20%;
  position: absolute;
  top: 65px;
  width: 651px;
}

.magicmango .kezdolapwrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 18px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.magicmango .navvonal {
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 71px;
}

.magicmango .text-wrapper-2 {
  color: white;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.magicmango .galeriawrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 128px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.magicmango .navvonal-2 {
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 57px;
}

.magicmango .kapcsolatwrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 222px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.magicmango .navvonal-3 {
  align-self: stretch;
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 100%;
}

.magicmango .arainkwrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 337px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.magicmango .foglalaswrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 425px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.magicmango .navvonal-4 {
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 64px;
}

.magicmango .informaciowrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 526px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.magicmango .nyelvek {
  align-items: center;
  border-radius: 5px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  right: 50px;
  overflow: hidden;
  position: absolute;
  top: 57px;
}

.magicmango .div-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.magicmango .img {
  height: 40px;
  position: relative;
  width: 40px;
}

.magicmango .text-wrapper-3 {
  color: #ffffff;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.magicmango .angol {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 34.04px;
  position: relative;
  width: 100%;
}

.magicmango .angolflag {
  height: 34.04px;
  position: relative;
  width: 40px;
}

.magicmango .cpimg {
  position: absolute;
  top: 801px; /* Ezt a pozíciót szükség szerint módosíthatod */
  height: 35px;
  width: auto; /* Automatikusan méretezi a képet a magassághoz */
}

.magicmango .cpimg.left-arrow {
  left: 50px; /* Bal oldali gomb pozíciója */
  background-color: transparent;
  top: 90vh;
}

.magicmango .cpimg.right-arrow {
  right: 50px; /* Jobb oldali gomb pozíciója */
  background-color: transparent;
  top: 90vh;
}

.magicmango .div-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 5% 10% 5%;
  position: relative;
  width: 80%;
}

.magicmango .div-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.magicmango .frame-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 463px;
  justify-content: space-around;
  position: relative;
  width: 100%;
}

.magicmango .frame-3 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
}

.magicmango .magicmangowrap {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.magicmango .text-wrapper-4 {
  color: #000000;
  
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.magicmango .magicmangovonal {
  align-self: stretch;
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 191, 52) 100%);
  border-radius: 10px;
  height: 3px;
  position: relative;
  width: 100%;
}

.magicmango .ferohely {
  align-items: center;
  background-color: #5d7329;
  border-radius: 15px;
  display: flex;
  gap: 8px;
  overflow: hidden;
  padding: 1px 14px;
  position: relative;
}

.magicmango .text-wrapper-5 {
  color: #ffffff;
  
  font-size: 23px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.magicmango .icon-user {
  height: 22px;
  position: relative;
  width: 20px;
}

.magicmango .szoveg {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 10px 0px;
  position: relative;
  width: 100%;
}

.magicmango .p {
  align-self: stretch;
  color: #000000;
  flex: 1;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.magicmango .ikonok {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  padding: 10px 0px;
  position: relative;
  width: 100%;
}

.magicmango .div-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  padding: 0px 1px;
  position: relative;
}

.magicmango .img-2 {
  height: 25px;
  position: relative;
  width: 25px;
}

.magicmango .text-wrapper-6 {
  color: #000000;
  
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.magicmango .div-5 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  position: relative;
}

.magicmango .div-6 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  height: 25px;
  padding: 10px 0px;
  position: relative;
}

.magicmango .img-3 {
  height: 25px;
  margin-bottom: -10px;
  margin-top: -10px;
  position: relative;
  width: 25px;
}

.magicmango .text-wrapper-7 {
  color: #000000;
  
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -5px;
  margin-top: -7px;
  position: relative;
  width: fit-content;
}

.magicmango .text-wrapper-8 {
  color: #000000;
  flex: 1;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.magicmango .gombwrap {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.magicmango .gombcont {
  height: 115px;
  position: relative;
  width: 236px;
}

.magicmango .gombvonal {
  background-color: #bf9f50;
  border-radius: 20px;
  height: 3px;
  left: 62px;
  position: absolute;
  top: 10px;
  width: 113px;
}

.magicmango .gomb {
  top: 27px;
  align-items: center;
  background-color: #5d7329;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  left: 3px;
  overflow: hidden;
  padding: 15px 29px;
  position: relative;
}

.magicmango .text-wrapper-9 {
  color: #ffffff;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}
