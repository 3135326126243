.input-container {
  max-height: 67%;
  overflow-y: scroll;
  border-radius: 10px; /* Lekerekített sarkok */
}

/* Webkit böngészők (Chrome, Safari) */
.input-container::-webkit-scrollbar {
  width: 12px;
}

.input-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track szín */
  border-radius: 10px; /* Lekerekített track */
}

.input-container::-webkit-scrollbar-thumb {
  background-color: #5d7329; /* Scrollbar szín */
  border-radius: 10px; /* Lekerekített scrollbar */
  border: 3px solid #f1f1f1; /* Fehér szegély */
}

/* Firefox */
.input-container {
  scrollbar-width: thin;
  scrollbar-color: #5d7329 #f1f1f1;
}

/* Internet Explorer és Edge */
.input-container {
  -ms-overflow-style: none; /* IE 10+ */
}

.input-container::-ms-scrollbar {
  width: 12px;
  height: 12px;
}

.input-container::-ms-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.input-container::-ms-scrollbar-thumb {
  background-color: #5d7329;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}
.daylabel {
    display: flex;
    justify-content: center;
    background-color: transparent;
    
}
.SegmentHolder {
    flex: 1;
    cursor: pointer;
    height: 2.25vh;
    max-height: 3vh;
}
.naptarHolder {
    /* Background and border */
    background: linear-gradient(135deg, #ffffff, #f5f5f5);
    border: 2px solid rgba(93, 115, 41, 1);
    border-radius: 15px;

    /* Box shadow for a 3D effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);

    /* Typography */
    /*font-family: 'Arial, sans-serif';*/
    color: #333;
    text-align: center;

    /* Transition for hover effects */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.naptarHolder:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 12px rgba(93, 115, 41, 0.4), 0 8px 25px rgba(93, 115, 41, 0.2);
}

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px); /* More pronounced blur effect */
    background-color: rgba(0, 0, 0, 0.6); /* Slightly darker semi-transparent background */
    z-index: 10000; /* Ensures the popup is above other content */
    flex-direction: column;
}

/* Style for the popup */
.popup {
    position: relative;
    width: 55vw; /* Slightly wider for more content */
    background: linear-gradient(135deg, #ffffff, #f5f5f5); /* Light gradient background */
    padding: 30px; /* More padding for better spacing */
    border-radius: 15px; /* Slightly more rounded corners */
    border: 2px solid rgba(93, 115, 41, 1); /* Matching border color */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 10px 30px rgba(0, 0, 0, 0.2); /* Stronger shadow for depth */
    text-align: center; /* Center text */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
    max-height: 75vh;
    min-height: 45vh;
}

.popup:hover {
    transform: scale(1.02); /* Slightly larger on hover */
    box-shadow: 0 10px 20px rgba(93, 115, 41, 0.4), 0 12px 35px rgba(93, 115, 41, 0.3); /* More pronounced shadow */
}
.popup1 {
    position: relative;
    width: 45vw; /* Slightly wider for more content */
    background: linear-gradient(135deg, #ffffff, #f5f5f5); /* Light gradient background */
    padding: 30px; /* More padding for better spacing */
    border-radius: 15px; /* Slightly more rounded corners */
    border: 2px solid rgba(93, 115, 41, 1); /* Matching border color */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 10px 30px rgba(0, 0, 0, 0.2); /* Stronger shadow for depth */
    text-align: center; /* Center text */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
    max-height: 76vh;
}

.popup1:hover {
    transform: scale(1.02); /* Slightly larger on hover */
    box-shadow: 0 10px 20px rgba(93, 115, 41, 0.4), 0 12px 35px rgba(93, 115, 41, 0.3); /* More pronounced shadow */
}

/* Style for the close button */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}
.NaptarReserveGomb {
    display: inline-block;
    padding: 15px 30px;
    font-size: 24px;
    font-weight: bold;
    color: white;
    background: rgba(93, 115, 41, 1); /* Background color */
    border: none;
    border-radius: 50px; /* Rounded edges */
    box-shadow: 0 4px 15px rgba(93, 115, 41, 0.4); /* Soft shadow */
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition for hover effects */
    margin-top: 20px;
}

.NaptarReserveGomb:hover {
    transform: scale(1.05); /* Slightly larger on hover */
    box-shadow: 0 6px 20px rgba(93, 115, 41, 0.5); /* More pronounced shadow */
}

.NaptarReserveGomb:active {
    transform: scale(0.98); /* Slightly smaller when clicked */
    box-shadow: 0 3px 10px rgba(93, 115, 41, 0.2); /* Less pronounced shadow */
}
.NaptarReserveGomb1 {
    display: inline-block;
    padding: 15px 30px;
    font-size: 24px;
    font-weight: bold;
    color: white;
    background: rgba(93, 115, 41, 1); /* Background color */
    border: none;
    border-radius: 50px; /* Rounded edges */
    box-shadow: 0 4px 15px rgba(93, 115, 41, 0.4); /* Soft shadow */
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition for hover effects */
    margin-top: 20px;
}

.NaptarReserveGomb1:hover {
    transform: scale(1.05); /* Slightly larger on hover */
    box-shadow: 0 6px 20px rgba(93, 115, 41, 0.5); /* More pronounced shadow */
}

.NaptarReserveGomb1:active {
    transform: scale(0.98); /* Slightly smaller when clicked */
    box-shadow: 0 3px 10px rgba(93, 115, 41, 0.2); /* Less pronounced shadow */
}
.SwitchButt {
    display: inline-block;
    padding: 1% 2%;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background: rgba(93, 115, 41, 1); /* Background color */
    border: none;
    border-radius: 30px; /* Rounded edges */
    box-shadow: 0 4px 15px rgba(93, 115, 41, 0.4); /* Soft shadow */
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition for hover effects */
}

.SwitchButt:hover {
    transform: scale(1.05); /* Slightly larger on hover */
    box-shadow: 0 6px 20px rgba(93, 115, 41, 0.5); /* More pronounced shadow */
}

.SwitchButt:active {
    transform: scale(0.98); /* Slightly smaller when clicked */
    box-shadow: 0 3px 10px rgba(93, 115, 41, 0.2); /* Less pronounced shadow */
}
/* Styling for the input field */
.stylish-input {
    width: 100%;
    padding: 15px;
    margin: 20px 0;
    box-sizing: border-box;
    font-size: 18px;
    border: 2px solid rgba(93, 115, 41, 1);
    border-radius: 10px;
    background-color: #f5f5f5;
    transition: border-color 0.3s ease;
}

.stylish-input:focus {
    border-color: #5d7329;
    outline: none;
}

/* Styling for the custom toggle switches */
.toggle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    min-height: 32%;
    justify-content: center;
}

.toggle-instructions {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.toggle-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 10px 0;
}

.toggle-label {
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

.toggle-switch {
    position: relative;
    width: 60px; /* Increase size */
    height: 30px; /* Increase size */
    background-color: #ccc;
    border-radius: 30px;
    transition: background-color 0.3s;
    cursor: pointer;
}

.toggle-switch::after {
    content: '';
    position: absolute;
    width: 26px; /* Increase size */
    height: 26px; /* Increase size */
    background-color: white;
    border-radius: 50%;
    top: 2px;
    left: 2px;
    transition: transform 0.3s;
}

.toggle-switch.active {
    background-color: rgba(93, 115, 41, 1);
}

.toggle-switch.active::after {
    transform: translateX(30px); /* Adjust for larger size */
}
.popup2 {
    position: relative;
    width: 600px;
    background: linear-gradient(135deg, #ffffff, #f5f5f5);
    padding: 30px;
    border-radius: 15px;
    border: 2px solid rgba(93, 115, 41, 1);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 10px 30px rgba(0, 0, 0, 0.2);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .popup2:hover {
    transform: scale(1.02);
    box-shadow: 0 10px 20px rgba(93, 115, 41, 0.4), 0 12px 35px rgba(93, 115, 41, 0.3);
  }
  .messagepopup {
    font-size: 1.5em;
    font-family: 'Comic Sans MS', cursive, sans-serif;
    padding: 10px;
    margin-top: 3.5%;
    border-radius: 10px;
    border: 2px solid #5d7329;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .form-group {
    margin: 15px 0;
  }
  
  .form-group label {
    display: inline-block;
    width: 150px;
    text-align: right;
    margin-right: 10px;
    font-weight: bold;
  }
  
  .form-group span {
    display: inline-block;
    min-width: 200px;
    text-align: left;
  }
  
  .messagepopup-closebutton {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: transparent;
    color: rgba(93, 115, 41, 1);
    font-size: 2.2em;
    cursor: pointer;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-weight: 700;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    transform: translate(15%,-15%);
  }
  
  .messagepopup-closebutton:hover {
    transform: scale(1.2);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transform: translate(15%,-15%);
  }
  
  .menu-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .menu-button {
    padding: 15px 30px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    background: rgba(93, 115, 41, 1);
    border: none;
    border-radius: 30px;
    box-shadow: 0 4px 15px rgba(93, 115, 41, 0.4);
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    width: 40%;
  }
  
  .menu-button:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 20px rgba(93, 115, 41, 0.5);
  }
  
  .menu-button:active {
    transform: scale(0.98);
    box-shadow: 0 3px 10px rgba(93, 115, 41, 0.2);
  }
  
  .menu-button.active {
    background: rgba(0, 0, 0, 0.8);
  }
  
  .payment-container {
    margin-top: 20px;
    text-align: center;
  }
  
  .payment-instructions {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .payment-container {
    margin: 45px 0px;
    text-align: center;
  }
  .payment-container1 {
    margin-top: 20px;
    text-align: center;
  }
  
  .payment-amount {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    min-height: 30px;
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  .input-group label {
    display: block;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    border: 2px solid rgba(93, 115, 41, 1);
    border-radius: 10px;
    background-color: #f5f5f5;
    transition: border-color 0.3s ease;
  }
  
  .input-group input:focus {
    border-color: #5d7329;
    outline: none;
  }
  .NaptarReserveGomb2 {
    display: inline-block;
    padding: 15px 30px;
    font-size: 24px;
    font-weight: bold;
    color: white;
    background: rgba(93, 115, 41, 1); /* Background color */
    border: none;
    border-radius: 50px; /* Rounded edges */
    box-shadow: 0 4px 15px rgba(93, 115, 41, 0.4); /* Soft shadow */
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition for hover effects */
    margin-top: 20px;
    transform: translate(105px, 210px);
}

.NaptarReserveGomb2:hover {
    transform: scale(1.05); /* Slightly larger on hover */
    box-shadow: 0 6px 20px rgba(93, 115, 41, 0.5); /* More pronounced shadow */
}

.NaptarReserveGomb2:active {
    transform: scale(0.98); /* Slightly smaller when clicked */
    box-shadow: 0 3px 10px rgba(93, 115, 41, 0.2); /* Less pronounced shadow */
}
.comingsoon {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}
.keppopup {
    max-width: 100%;
    max-height: 100%;
    min-width: 30vw;
    min-height: 30vh;
    max-height: 80vh;
    max-width: 80vw;
    object-fit: contain; /* Ensure the image scales correctly */
    margin: 0;
    transition: opacity 0.3s ease-out;
  }
  .keppopup.fade {
    opacity: 0;
  }
  /* TransformDiv.css */
.container {
    width: 100%;
    height: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1%;
  }
  
  .label {
    transform: translateY(25%);
    font-size: 1.2em;
    margin-bottom: 5px;
    transition: transform 1s ease, opacity 1s ease;
  }
  
  .boxes {
    display: flex;
    justify-content: space-around;
    width: 100%;

  }
  
  .box {
    width: 30%;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border: 2px solid transparent;
    transition: background-color 1s ease, border-color 1s ease, clip-path 1s ease;
    padding: 2px;
  }
  
  .border-only {
    background-color: transparent !important;
    color: black;
  }
  .coordinate-control {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 14px;
    z-index: 1000; /* Ensure it stays on top */
    user-select: text; /* Make text selectable */
  }
  
  .cut-left {
    clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);
  }
  
  .cut-right {
    clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 15% 100%);
  }
  .navlinkek-n {
    height: 35px;
    left: 25%;
    position: absolute;
    top: 65px;
    width: 651px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .navitem-n {
    position: relative;
    cursor: pointer;
  }
  
  .text-wrapper-n {
    color: white;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
    padding: 5px;
  }
  
  .navvonal-n {
    background-color: #bf9f50;
    height: 3px;
    position: absolute;
    bottom: 100%; /* Alapértelmezett pozíció a szöveg felett */
    left: 50%;
    transform: translateX(-50%) scaleX(0);
    width: 100%;
    transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55), bottom 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    border-radius: 10px;
  }
  .navvonal-n-dea {
    background-color: #bf9f50;
    height: 3px;
    position: absolute;
    bottom: 110%; /* Alapértelmezett pozíció a szöveg felett */
    left: 50%;
    transform: translateX(-50%) scaleX(1);
    width: 100%;
    border-radius: 10px;
  }
  
  .navitem-n:hover .navvonal-n {
    transform: translateX(-50%) scaleX(1);
    bottom: 110%; /* Animációval megjelenik közvetlenül a szöveg felett */
  } 
  .navitem-n:hover .text-wrapper-n {
    animation: textPop 0.5s ease forwards;
  }
  /*KEPPOPUP*/
  .popup-header {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .close-buttonkep {
    font-size: 24px;
    color: white;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .popup-content {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  
  .arrow-button {
    font-size: 24px;
    color: white;
    background: none;
    border: none;
    cursor: pointer;
    margin: 0 20px;
  }
  
  .left-arrow {
    position: absolute;
    left: 10px;
    z-index: 10000;
  }
  
  .right-arrow {
    position: absolute;
    right: 10px;
    z-index: 10000;
  }
  .left-arrow2 {
    position: absolute;
    left: 10px;
  }
  
  .right-arrow2 {
    position: absolute;
    right: 10px;
  }
  .arwrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    transform: translateY(-10px);
  }
  .toggle-prices {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    font-size: 20px;
    transform: translateY(-10px);
    font-weight: bold;
    color: #333;
  }
  .loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #5d7329;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999; /* Make sure it's on top of everything else */
    transition: background-color 1s ease;
  }
  .w-inline-block {
    max-width: 100%;
    display: inline-block;
  }
  
  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .pulsing-logo {
    width: 100px;
    height: 180px;
    animation: pulse 3s infinite;
  }
  
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes textPop {
    0% {
      color: white;
    }
    25% {
      color: #b9b4a7;
    }
    50% {
      color: #ad9e77;
    }
    75% {
      color: #b9b4a7;
    }
    100% {
      color: white;
    }
  }
  /*TABLET*/
  @media only screen and (max-width: 1024px) {
  
  }
  /*MOBILE*/
  @media only screen and (max-width: 768px) {
    .popup {
      width: 80vw;
    }
    .logo-container {
      transform: translateY(-5vh);
    }
    .naptarHolder:hover {
      transform: none;
      box-shadow: none;
    }
    .popup:hover {
      transform: none; /* Slightly larger on hover */
      box-shadow: none; /* More pronounced shadow */
    }
    .popup1,.popup2 {
      max-width: 80vw;
      min-width: 70vw;
      max-height: 60vh;
    }
    .stylish-input {
      padding: 5px;
      margin: 10px 0;
      font-size: 14px;
    }
    .toggle-instructions {
      font-size: 12px;
    }
    .toggle-wrapper {
      width: 100%;
  }
  .toggle-label {
    font-size: 9px;
}
.toggle-switch {
  width: 30px;
  height: 15px;

}
.toggle-switch::after {
  width: 13px;
  height: 13px;
  transform: translateY(-1px);
}
.toggle-switch.active::after {
  transform: translate(14px, -1px);
}
.popup2 {
  max-height: 75vh;
}
.menu-button {
  font-size: 14px;
  width: 48%;
}
.payment-amount {
  font-size: 19px;
}
.input-group label {
  font-size: 14px;
}
.asdasdasd {
  font-size: 10px;
}
.left-arrow {
  position: absolute;
  left: 5px;
  height: 300px;
  top: 83vh;
  margin: 0;
}

.right-arrow {
  position: absolute;
  right: 5px;
  height: 300px;
  top: 83vh;
  margin: 0;
}
.left-arrow2 {
  position: absolute;
  left: 5px;
  height: 300px;
  margin: 0;
}

.right-arrow2 {
  position: absolute;
  right: 5px;
  height: 300px;
  margin: 0;
}
.toggle-prices {
  font-size: 10px;
  transform: translateY(0px);
}
.box {
  font-size: 10px;
}
  }
