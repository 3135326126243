.greenpassion {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100vw;
}

.greenpassion .gpherokep {
  align-items: center;
  align-self: stretch;
  background-image: url(../../img2/herokep.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 897px;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
}

.greenpassion .gpframe {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
  background-position: 50% 50%;
  background-size: cover;
  transition: opacity 2s ease-in-out;
}

.greenpassion .cpimg {
  position: absolute;
  top: 801px; /* Ezt a pozíciót szükség szerint módosíthatod */
  height: 35px;
  width: auto; /* Automatikusan méretezi a képet a magassághoz */
}

.greenpassion .cpimg.left-arrow {
  left: 50px; /* Bal oldali gomb pozíciója */
  background-color: transparent;
  top: 90vh;
}

.greenpassion .cpimg.right-arrow {
  right: 50px; /* Jobb oldali gomb pozíciója */
  background-color: transparent;
  top: 90vh;
}


.greenpassion .gpoverlap-group {
  height: 782px;
  left: 1px;
  position: absolute;
  top: 19px;
  width: 100%;
}

.greenpassion .gpnavcont {
  height: 207px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.greenpassion .gplogo {
  background-position: 50% 50%;
  background-size: cover;
  height: 90px;
  z-index: 1000;
  left: 20px;
  position: absolute;
  top: 20px;
  width: 51px;
}

.greenpassion .gpnavlinkek {
  height: 35px;
  left:20%;
  position: absolute;
  top: 65px;
  width: 651px;
}

.greenpassion .gpkezdolapwrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 18px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.greenpassion .gpnavvonal {
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 71px;
}

.greenpassion .gptext-wrapper {
  color: white;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.greenpassion .gpgaleriawrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 128px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.greenpassion .gpdiv {
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 57px;
}

.greenpassion .gpkapcsolatwrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 222px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.greenpassion .gpnavvonal-2 {
  align-self: stretch;
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 100%;
}

.greenpassion .gparainkwrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 337px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.greenpassion .gpfoglalaswrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 425px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.greenpassion .gpnavvonal-3 {
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 64px;
}
.greenpassion .gpnavvonal-4 {
  background-color: #bf9f50;
  height: 3px;
  position: relative;
  width: 64px;
}

.greenpassion .gpinformaciowrap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 526px;
  padding: 10px 0px;
  position: absolute;
  top: -10px;
}

.greenpassion .gpnyelvek {
  align-items: center;
  border-radius: 5px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  right: 50px;;
  overflow: hidden;
  position: absolute;
  top: 57px;
}

.greenpassion .gpdiv-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.greenpassion .gpimg-2 {
  height: 40px;
  position: relative;
  width: 40px;
}

.greenpassion .gptext-wrapper-2 {
  color: #ffffff;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.greenpassion .gpangol {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 34.04px;
  position: relative;
  width: 100%;
}

.greenpassion .gpangolflag {
  height: 34.04px;
  position: relative;
  width: 40px;
}

.greenpassion .gpframe-2 {
  height: 80vh;
  left: 144px;
  position: absolute;
  top: 14vh;
  width: 552px;
  overflow: hidden;
}

.greenpassion .gptext-wrapper-3 {
  color: white;
  
  font-size: 30px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 381px;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.greenpassion .gptext-wrapper-4 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, rgb(254, 254, 254) 0%, rgb(93, 115, 41) 100%);
  background-clip: text;
  color: white;
  font-size: 75px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-fill-color: transparent;
  top: 290px;
  white-space: nowrap;
  width: 552px;
  overflow: hidden;
  position: relative;
}

.greenpassion .gptext-wrapper-4::before {
  content: 'Green Passion';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(93, 115, 41, 0) 0%,
    rgba(93, 115, 41, 0.5) 50%,
    rgba(93, 115, 41, 0) 100%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s forwards;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.greenpassion .gpdiv-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 5% 10% 5%;
  position: relative;
  width: 80%;
}

.greenpassion .gpdiv-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.greenpassion .gpframe-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.greenpassion .gpgreenpassionwrap {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.greenpassion .gptext-wrapper-5 {
  color: #000000;
  
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.greenpassion .gpcrystal-vonal {
  align-self: stretch;
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(93, 115, 41) 100%);
  border-radius: 10px;
  height: 3px;
  position: relative;
  width: 100%;
}

.greenpassion .gpferohely {
  align-items: center;
  background-color: #5d7329;
  border-radius: 15px;
  display: flex;
  gap: 8px;
  overflow: hidden;
  padding: 1px 14px;
  position: relative;
}

.greenpassion .gptext-wrapper-6 {
  color: #ffffff;
  
  font-size: 23px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.greenpassion .gpicon-user {
  height: 22px;
  position: relative;
  width: 20px;
}

.greenpassion .gpszoveg {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 10px 0px;
  position: relative;
  width: 100%;
}

.greenpassion .gpp {
  align-self: stretch;
  color: #000000;
  flex: 1;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.greenpassion .gpikonok {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  padding: 10px 0px;
  position: relative;
  width: 100%;
}

.greenpassion .gpdiv-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  padding: 0px 1px;
  position: relative;
}

.greenpassion .gpimg-3 {
  height: 25px;
  position: relative;
  width: 25px;
}

.greenpassion .gptext-wrapper-7 {
  color: #000000;
  
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.greenpassion .gpdiv-5 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  position: relative;
}

.greenpassion .gpdiv-6 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  height: 25px;
  padding: 10px 0px;
  position: relative;
}

.greenpassion .gpimg-4 {
  height: 25px;
  margin-bottom: -10px;
  margin-top: -10px;
  position: relative;
  width: 25px;
}

.greenpassion .gptext-wrapper-8 {
  color: #000000;
  
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -5px;
  margin-top: -7px;
  position: relative;
  width: fit-content;
}

.greenpassion .gptext-wrapper-9 {
  color: #000000;
  flex: 1;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.greenpassion .gpgombwrap {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.greenpassion .gpgombcont {
  height: 115px;
  position: relative;
  width: 236px;
}

.greenpassion .gpgombvonal {
  background-color: #bf9f50;
  border-radius: 20px;
  height: 3px;
  left: 62px;
  position: absolute;
  top: 10px;
  width: 113px;
}

.greenpassion .gpgomb {
  top: 27px;
  align-items: center;
  background-color: #5d7329;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  left: 3px;
  overflow: hidden;
  padding: 15px 29px;
  position: relative;
}

.greenpassion .gptext-wrapper-10 {
  color: #ffffff;
  
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}
.left-arrow {
  height: 35px;
}

.right-arrow {
  height: 35px;
}